import { action, observable, computed } from 'mobx';
import { find } from 'lodash';
import { notifier } from 'tc-biq-design-system';

import http from 'App/services/http';

import {
  formatFieldsToArray,
  extractCursorQuery,
  mapColumns,
  filterVisibleFields,
  resetUrlQueryParams,
  formatFilterQueries,
} from './gridStoreUtils';

const initialState = {
  data: [],
  optionFields: {},
  columns: [],
  paginationQuery: {
    ordering: '-id',
    limit: 25,
    cursor: '',
  },
  filterQuery: {},
  cursor: {
    prev: null,
    next: null,
  },
};

const text = {
  EXPORT_SUCCESS: 'Data export successfully initiated, check My data exports on User Profile',
  EXPORT_FAILED: 'Failed to initate data export',
};

export default class GridStore {
  constructor(FiltersStore) {
    this.filters = FiltersStore;
  }

  @observable data = [];

  @observable optionFields = {};

  @observable columns = [];

  @observable visibleFields = {};

  @observable paginationQuery = {
    ordering: '-id',
    limit: 25,
    cursor: '',
  };

  @observable customQuery = {}

  @observable initialFilterQuery = {};

  @observable cursor = {
    prev: null,
    next: null,
  };

  @observable columnManager = {
    allFields: [],
    columnsState: [],
  };

  @action.bound setData(data) {
    this.data = data;
  }

  @action.bound fetchNextPage(next, prev, limit, ordering) {
    this.pagination = {
      next,
      prev,
      limit,
      ordering,
    };
  }

  @action.bound setCursorDirection(direction) {
    if (direction === 'next') this.paginationQuery.cursor = this.cursor.next;
    if (direction === 'prev') this.paginationQuery.cursor = this.cursor.prev;
  }

  @action.bound setCursors(next, prev) {
    this.cursor.next = next ? extractCursorQuery(next) : null;
    this.cursor.prev = prev ? extractCursorQuery(prev) : null;
  }

  @action.bound resetCursor() {
    this.paginationQuery.cursor = '';
  }

  @action.bound changePageSize(pageSize) {
    this.paginationQuery.limit = pageSize;
  }

  @action.bound setOrdering(ordering) {
    this.paginationQuery.ordering = ordering;
    this.paginationQuery.cursor = '';
  }

  @action.bound setOptionsFields({
    fields,
    modifiers,
    customColumns,
    setDefaultRenderer,
    filterOptions,
    viewName,
  }) {
    this.filters.updateViewName(viewName);
    this.setFilterOptions(filterOptions);
    this.optionFields = fields;
    this.visibleFields = filterVisibleFields(fields);
    this.filters.updateFields(fields);
    this.columns = mapColumns(this.visibleFields, modifiers, customColumns, setDefaultRenderer);
    this.columnManager = {
      allFields: formatFieldsToArray(this.optionFields),
      columnsState: formatFieldsToArray(this.visibleFields),
    };
  }

  @action.bound addInitialFilters(filters) {
    this.initialFilterQuery = filters;
  }

  @action.bound setFilterOptions(filterOptions) {
    this.filterOptions = { ...filterOptions };
  }

  @action.bound addCustomQuery(customQuery) {
    this.customQuery = customQuery;
  }

  @action.bound resetCustomQuery() {
    this.customQuery = {};
  }

  @action.bound resetTable() {
    this.paginationQuery = { ...initialState.paginationQuery };
    this.data = [...initialState.data];
    this.columns = [...initialState.columns];
    this.visibleFields = { ...initialState.visibleFields };
    this.optionFields = {};
    this.filters.resetFilters();
    resetUrlQueryParams();
  }

  // Column manager methods
  @action.bound updateFieldVisibility(key) {
    const { allFields } = this.columnManager;
    const checkedField = find(allFields, { key });
    checkedField.visible = !checkedField.visible;
    this.columnManager.columnsState = allFields.filter(field => field.visible);
  }

  @action.bound toggleAllFieldsVisibility(allVisible) {
    const updatedFields = this.columnManager.allFields.map(field => ({
      ...field,
      visible: allVisible || false,
    }));
    this.columnManager.columnsState = updatedFields.filter(field => field.visible);
    this.columnManager.allFields = updatedFields;
  }

  @action.bound setColumnsState(columnsState) {
    this.columnManager.columnsState = columnsState;
  }

  @action.bound resetColumnsState() {
    this.columnManager = {
      allFields: formatFieldsToArray(this.optionFields),
      columnsState: formatFieldsToArray(this.visibleFields),
    };
  }

  @action.bound async exportData({ path }) {
    const payload = {
      path,
      export_filters: formatFilterQueries(this.filters.query),
      export_fields: Object.keys(this.visibleFields),
    };
    try {
      await http.post('/users/data-exports/', payload);
      notifier.success(text.EXPORT_SUCCESS);
    } catch {
      notifier.error(text.EXPORT_FAILED);
    }
  }

  // Table query with all parameters
  @computed get query() {
    return {
      ...this.paginationQuery,
      ...this.filters.query,
      ...this.initialFilterQuery,
      ...this.customQuery,
    };
  }
}
