import React from 'react';
import { number, string, bool } from 'prop-types';
import AvatarNameCell from './AvatarNameCell';

const propTypes = {
  id: number.isRequired,
  fullName: string.isRequired,
  avatar: string,
  subTitle: string,
  disableRouting: bool,
  subRoute: string,
};

const defaultProps = {
  avatar: null,
  subTitle: null,
  disableRouting: false,
  subRoute: '/my-details',
};

const UserCell = ({ id, fullName, avatar, subTitle, disableRouting, subRoute }) => (
  <AvatarNameCell
    id={id}
    type="User"
    displayString={fullName}
    displaySubString={subTitle}
    avatarSource={avatar}
    route={disableRouting ? null : '/settings/users/'}
    subRoute={disableRouting ? null : subRoute}
  />
);

UserCell.propTypes = propTypes;
UserCell.defaultProps = defaultProps;
export default UserCell;
