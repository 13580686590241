import React from 'react';
import { Space, Avatar, HyperLink } from 'tc-biq-design-system';
import { Link } from 'react-router-dom';
import { number, string } from 'prop-types';

import './AvatarNameCell.scss';

const propTypes = {
  id: number.isRequired,
  type: string.isRequired,
  displayString: string.isRequired,
  // NOTE: Avatar source is the URL of the image, avatar initials are a fallback option.
  avatarSource: string,
  avatarInitials: string,
  route: string,
  subRoute: string,
  displaySubString: string,
};

const defaultProps = {
  avatarSource: null,
  avatarInitials: null,
  route: null,
  subRoute: null,
  displaySubString: null,
};

const AvatarNameCell = ({
  id,
  type,
  displayString = '',
  displaySubString,
  avatarSource,
  avatarInitials,
  route,
  subRoute,
}) => {
  let objectData;

  if (!id) {
    return null;
  }

  if (displayString.trim() === '') {
    objectData = <span className="tc-paragraph-regular">{`${type} ID: ${id}`}</span>;
  } else {
    const initials = avatarInitials
      || displayString
        .split(' ')
        .map(text => text[0])
        .join('');
    objectData = (
      <div className="avatar-name__cell">
        <div className="avatar-name__cell--initials">
          <Avatar initials={initials} src={avatarSource} />
        </div>
        <Space size={8} />
        <span className="tc-paragraph-regular avatar-name__cell--text">
          <div className="truncate-text">{displayString}</div>
          {displaySubString && (
            <span className="tc-micro-regular text-neutral-700">{displaySubString}</span>
          )}
        </span>
      </div>
    );
  }

  if (route) {
    return (
      <div className="avatar-name" data-recording-sensitive>
        <HyperLink>
          <Link to={`${route}${id}${subRoute || ''}`}>{objectData}</Link>
        </HyperLink>
      </div>
    );
  }

  return objectData;
};

AvatarNameCell.propTypes = propTypes;
AvatarNameCell.defaultProps = defaultProps;
export default AvatarNameCell;
