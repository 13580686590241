const errorFormatter = (errors) => {
  let temp = {};
  Object.keys(errors).forEach((key) => {
    temp = {
      ...temp,
      [key]: typeof errors[key] === 'object' ? errors[key][0] : errors[key],
    };
  });
  return temp;
};

export default errorFormatter;
