import http from 'App/services/http';

const fetchCagData = id => http.get(`/contact-access-groups/${id}/`);

const deleteContactAccessGroup = id => http.delete(`/contact-access-groups/${id}/`);

const addContactAccessGroup = payload => http.post('/contact-access-groups/', payload);

const editContactAccessGroup = (id, payload) => http.put(`/contact-access-groups/${id}/`, payload);

const fetchCagOptions = id => http.options(`/contact-access-groups/${id}/`);

const addBindings = (groupId, users) => http.post(`/contact-access-groups/${groupId}/bindings/`, users);

const removeBindings = (groupId, users) => http.delete(`/contact-access-groups/${groupId}/bindings/delete/`, users);

const fetchUserBindings = (groupId, params) => http.get(`/contact-access-groups/${groupId}/bindings/`, { params });

const fetchCagMetadata = () => http.get('/contact-access-groups/metadata/');

export {
  fetchCagData,
  fetchCagOptions,
  addBindings,
  removeBindings,
  fetchUserBindings,
  fetchCagMetadata,
  deleteContactAccessGroup,
  addContactAccessGroup,
  editContactAccessGroup,
};
