import { action, observable, runInAction } from 'mobx';
import { notifier } from 'tc-biq-design-system';

import {
  fetchSegment,
  patchSegment,
  createSegment,
  fetchFieldsDef,
  deleteSegment,
  fetchSegmentMetadata,
  createAudience,
  pauseAudience,
  resumeAudience,
  deleteAudience,
} from 'Automation/services/segmentsService';
import formsStore from 'App/rootStore/formsStore';
import formatQueryRulesArrayValues from 'App/services/utilities/formatQueryRulesArrayValues';
import getErrorMessage from 'App/services/utilities/getErrorMessage';

const text = {
  SEGMENT_FAILED: 'Failed to fetch segment data',
  CREATE_SUCCESS: 'Successfully created segment',
  CREATE_FAILED: 'Failed to create segment',
  EDIT_SUCCESS: 'Successfully edited segment',
  EDIT_FAILED: 'Failed to edit segment',
  DELETE_SUCCESS: 'Successfully deleted segment',
  DELETE_FAILED: 'Failed to delete segment',
  FIELDS_FAILED: 'Failed to fetch fields definition',
  CREATE_AUDIENCE_SUCCESS: 'Successfully created an audience',
  CREATE_AUDIENCE_FAILED: 'Failed to create an audience',
  AD_NETWORK_AUDIENCE_ALREADY_EXIST: ' audience already exist for segment!',
  DELETE_AUDIENCE_SUCCESS: 'Successfully deleted an audience',
  PAUSE_AUDIENCE_SUCCESS: 'Successfully paused an audience',
  RESUME_AUDIENCE_SUCCESS: 'Successfully resumed an audience',
};

export default class SegmentsStore {
  constructor(queryBuilder) {
    this.queryBuilder = queryBuilder;
  }

  @observable requestInProgress = {
    fetchSegment: false,
    fieldsDef: false,
    create: false,
    edit: false,
  };

  @observable segment = {};

  @observable fieldsDef = {};

  @action.bound async fetchSegment(id) {
    formsStore.segmentForm.resetFieldsData();
    this.queryBuilder.resetQueries();
    runInAction(() => {
      this.requestInProgress.fetchSegment = true;
    });
    try {
      const response = await fetchSegment(id);
      runInAction(() => {
        this.segment = response.data;
        formsStore.segmentForm.setFieldsData(response.data);
        this.queryBuilder.setQueries(response.data.query);
      });
    } catch {
      notifier.error(text.SEGMENT_FAILED);
    } finally {
      runInAction(() => {
        this.requestInProgress.fetchSegment = false;
      });
    }
  }

  @action.bound async fetchFieldsDef() {
    this.requestInProgress.fieldsDef = true;
    try {
      const response = await fetchFieldsDef();
      const metadataResponse = await fetchSegmentMetadata();
      runInAction(() => {
        this.fieldsDef = response.data.actions.GET;
      });
      this.queryBuilder.setFields(formatFields(response.data.actions.GET, metadataResponse.data));
      this.queryBuilder.setFieldsMetadata(metadataResponse.data);
    } catch (e) {
      notifier.error(text.FIELDS_FAILED);
    } finally {
      runInAction(() => {
        this.requestInProgress.fieldsDef = false;
      });
    }
  }

  @action.bound async createSegment(history) {
    this.requestInProgress.create = true;
    try {
      const { name, description } = formsStore.segmentForm.data;
      await createSegment({
        name,
        description,
        query: formatQueryRulesArrayValues(this.queryBuilder.queries),
      });
      notifier.success(text.CREATE_SUCCESS);
      history.push('/automation/segments');
    } catch (e) {
      notifier.error(text.CREATE_FAILED);
      if (e.response && e.response.data) {
        const errMessage = e.response.data?.query[0];
        notifier.error(getErrorMessage(errMessage));
        formsStore.segmentForm.setFieldsErrors(e.response.data);
      }
    } finally {
      runInAction(() => {
        this.requestInProgress.create = false;
      });
    }
  }

  @action.bound async editSegment(id, history) {
    this.requestInProgress.edit = true;
    const { name, description } = formsStore.segmentForm.data;
    try {
      await patchSegment(id, {
        name,
        description,
        query: formatQueryRulesArrayValues(this.queryBuilder.queries),
      });
      notifier.success(text.EDIT_SUCCESS);
      history.push('/automation/segments');
    } catch {
      notifier.error(text.EDIT_FAILED);
    } finally {
      runInAction(() => {
        this.requestInProgress.edit = false;
      });
    }
  }

  @action.bound removeSegment = async (id, onSuccess) => {
    try {
      await deleteSegment(id);
      notifier.success(text.DELETE_SUCCESS);
      onSuccess();
    } catch {
      notifier.error(text.DELETE_FAILED);
    }
  };

  @action.bound createAudience = async (segmentID, existingAudiences, adNetwork, onSuccess) => {
    if (existingAudiences.find(a => a.ad_network === adNetwork)) {
      notifier.error(adNetwork + text.AD_NETWORK_AUDIENCE_ALREADY_EXIST);
      return;
    }
    try {
      await createAudience(segmentID, { ad_network: adNetwork });
      notifier.success(text.CREATE_AUDIENCE_SUCCESS);
      onSuccess();
    } catch (err) {
      if (err.response && err.response.data.detail) {
        notifier.error(err.response.data.detail);
      } else {
        notifier.error(text.CREATE_AUDIENCE_FAILED);
      }
    }
  };

  @action.bound pauseAudience = async (segment_id, audience_id, onSuccess) => {
    try {
      await pauseAudience(segment_id, audience_id);
      notifier.success(text.PAUSE_AUDIENCE_SUCCESS);
      onSuccess();
    } catch (e) {
      notifier.error(e.response.data[0]);
    }
  }

  @action.bound resumeAudience = async (segment_id, audience_id, onSuccess) => {
    try {
      await resumeAudience(segment_id, audience_id);
      notifier.success(text.RESUME_AUDIENCE_SUCCESS);
      onSuccess();
    } catch (e) {
      notifier.error(e.response.data[0]);
    }
  }

  @action.bound deleteAudience = async (segment_id, audience_id, onSuccess) => {
    try {
      await deleteAudience(segment_id, audience_id);
      notifier.success(text.DELETE_AUDIENCE_SUCCESS);
      onSuccess();
    } catch (e) {
      notifier.error(e.response.data[0]);
    }
  }
}

function formatFields(optionFields, metadataFields) {
  const fields = {};
  Object.keys(metadataFields).forEach((key) => {
    fields[key] = {
      ...metadataFields[key],
      ...optionFields[key],
      label: key
        .split('.')[0]
        .toLowerCase()
        .split('_')
        .map((string, index) => {
          if (index === 0) {
            return string.charAt(0).toUpperCase() + string.substring(1);
          }
          return string;
        })
        .join(' '),
    };
  });
  return fields;
}
