import omit from 'lodash/omit';
import http from 'App/services/http';

const fetchContactData = id => http.get(`/contacts/${id}/`);

const fetchContactOptions = () => http.options('/contacts/');

const editContactBasicInfo = (id, payload) => http.patch(`/contacts/${id}/`, payload);

const fetchContactInfoLayout = () => http.get('contacts/info-config/');

const fetchContactSignupData = id => http.get(`contacts/${id}/signup-info/`);

const fetchContactMifidData = id => http.get(`contacts/${id}/nic/`);

const fetchContactKyc = id => http.get(`contacts/${id}/kyc/`);

const fetchContactWalletBalances = (contact_id, wallet_ids) => http.get(`contacts/${contact_id}/wallets/balances/`, {
  params: { wallets_ids: wallet_ids.join() },
});

const fetchNotesData = (id, params) => http.get(`/contacts/${id}/notes/`, { params });

const fetchNoteData = (id, noteId) => http.get(`/contacts/${id}/notes/${noteId}`);

const addNote = (id, payload) => http.post(`/contacts/${id}/notes/`, { content: payload });

const updateNote = (contactId, noteId, payload) => http.patch(`/contacts/${contactId}/notes/${noteId}/`, { content: payload });

const deleteNote = (contactId, id) => http.delete(`/contacts/${contactId}/notes/${id}`);

const sendEmail = (id, payload, attachments) => {
  const body = new FormData();

  Object.entries(omit(payload, ['cc', 'bcc'])).forEach(([key, value]) => {
    body.append(key, value);
  });

  payload.cc.forEach((c) => {
    body.append('cc', c);
  });

  payload.bcc.forEach((c) => {
    body.append('bcc', c);
  });

  attachments.forEach((attachment) => {
    body.append('attachments', attachment);
  });

  const config = { headers: { 'content-type': 'multipart/form-data' } };

  return http.post(`/contacts/${id}/email/`, body, config);
};

const sendSms = (id, payload) => http.post(`/contacts/${id}/sms/`, payload);

const sendWhatsApp = (id, payload) => http.post(`/contacts/${id}/whatsapp/`, payload);

const logCall = payload => http.post('communications/call-logs/', payload);

const approveContact = id => http.patch(`contacts/${id}/approve/`);

const rejectContact = id => http.patch(`contacts/${id}/reject/`);

const searchTeams = query => http.get('users/teams', { params: { search: query } });

const searchUsers = (query, teamID) => http.get('users', {
  params: { search: query, ...(teamID ? { team_binding__team: teamID } : {}) },
});

const fetchUser = id => http.get(`users/${id}`);

const assignManager = (contactId, managerId, managerKey) => http.patch(`contacts/${contactId}/`, { [managerKey]: managerId });

const fetchEmailInboxes = () => http.get('/communications/email-inbox');

const fetchEmailLog = id => http.get(`communications/email-logs/${id}`);

// BULK ACTIONS
const bulkRemoveAssigness = payload => http.post('/contacts/bulk-remove-assignees/', payload);

const bulkRemoveTags = payload => http.post('/contacts/bulk-remove-tags/', payload);

const bulkSetAssignees = payload => http.post('/contacts/bulk-set-assignees/', payload);

const bulkSetTags = payload => http.post('/contacts/bulk-set-tags/', payload);

export {
  fetchContactData,
  fetchContactOptions,
  editContactBasicInfo,
  fetchContactInfoLayout,
  fetchContactSignupData,
  fetchContactMifidData,
  fetchContactWalletBalances,
  fetchContactKyc,
  fetchNotesData,
  addNote,
  updateNote,
  deleteNote,
  sendEmail,
  sendSms,
  sendWhatsApp,
  approveContact,
  rejectContact,
  searchTeams,
  searchUsers,
  assignManager,
  fetchUser,
  fetchEmailInboxes,
  fetchNoteData,
  fetchEmailLog,
  logCall,
  bulkRemoveAssigness,
  bulkRemoveTags,
  bulkSetAssignees,
  bulkSetTags,
};
