import { action, observable } from 'mobx';
import { isEmpty } from 'lodash';

import { formatValuePerType } from 'App/components/Filters/filterStoreUtils';
import { getAffectedObject, removeAffectedCondition, formatFields } from './queryBuilderStoreUtils';

export default class QueryBuilderStore {
  @observable queries = {
    condition: 'AND',
    rules: [],
  };

  @observable fields = {};

  @observable fieldsMetadata = {};

  @action.bound setQueries(queries) {
    this.queries = queries;
  }

  @action.bound setFieldsMetadata(metaData, labelFormatter) {
    this.fieldsMetadata = metaData;
    if (isEmpty(this.fields)) this.fields = formatFields(metaData, labelFormatter);
  }

  @action.bound setFields(fields) {
    this.fields = fields;
  }

  @action.bound updateCondition(value, identifier) {
    if (identifier && identifier.length !== 0) {
      const temp = [...this.queries.rules];
      const affectedCondition = getAffectedObject(temp, identifier);
      affectedCondition.condition = value;
      this.queries.rules = [...temp];
    } else {
      this.queries.condition = value;
    }
  }

  @action.bound updateConditionRule(data, identifier) {
    const affectedCondition = getAffectedObject(this.queries.rules, identifier);
    Object.keys(data).forEach((key) => {
      if (key === 'value') {
        const { type, operator, value } = data;
        affectedCondition[key] = formatValuePerType(type, operator, value);
      } else {
        affectedCondition[key] = key === 'type' && this.fieldsMetadata[data.field]
          ? this.fieldsMetadata[data.field].type
          : data[key];
      }
    });
  }

  @action.bound updateEvent(data, identifier) {
    if (identifier && identifier.length !== 0) {
      Object.assign(getAffectedObject(this.queries.rules, identifier), data);
    }
  }

  @action.bound addCondition(data, identifier) {
    if (identifier && identifier.length !== 0) {
      const temp = [...this.queries.rules];
      const affectedCondition = getAffectedObject(temp, identifier);
      affectedCondition.rules = [...affectedCondition.rules, data];
      this.queries.rules = [...temp];
    } else {
      this.queries.rules = [...this.queries.rules, data];
    }
  }

  @action.bound removeCondition(identifier, removeAll) {
    if (removeAll) {
      this.queries.rules = [];
    } else {
      this.queries.rules = removeAffectedCondition(this.queries.rules, identifier);
    }
  }

  @action.bound resetQueries() {
    this.queries = {
      condition: 'AND',
      rules: [],
    };
    this.fields = {};
    this.fieldsMetadata = {};
  }
}
