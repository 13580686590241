import { observable, action, runInAction, computed } from 'mobx';
import { notifier } from 'tc-biq-design-system';
import { isEmpty } from 'lodash';

import {
  fetchCampaignData,
  createCampaign,
  updateCampaign,
  fetchCampaignsOptions,
  sendTestEmail,
  startCampaign,
} from 'Marketing/services/CampaignService';
import stores from 'App/rootStore';
import { fetchEventDefinitionData } from 'Settings/Sections/Events/services/EventsService';
import {
  formatEventLabel,
  formatEventNestedKeys,
} from 'App/components/QueryBuilderFactory/queryBuilderStoreUtils';
import formatPayload from 'App/services/utilities/formatPayload';
import formatQueryRulesArrayValues from 'App/services/utilities/formatQueryRulesArrayValues';

const text = {
  FETCH_CAMPAIGN_FAILED: 'Failed to fetch campaign',
  UPDATE_SUCCESS: 'Successfully updated campaign',
  UPDATE_FAILED: 'Failed to update campaign',
  CREATE_SUCCESS: 'Successfully created campaign',
  CREATE_DRAFT_SUCCESS: 'Campaign created as draft',
  CREATE_FAILED: 'Failed to create campaign',
  OPTIONS_FAILED: 'Failed to get fields data',
  TEST_MESSAGE_SUCCESS: 'Test message sent. Please check your inbox',
  TEST_MESSAGE_FAILED: 'Failed to send test email',
  START_SUCCESS: 'Successfully started campaign',
  START_FAILED: 'Failed to start campaign',
};

export default class CampaignsStore {
  constructor(queryBuilderStore) {
    this.queryBuilder = queryBuilderStore;
  }

  @observable campaign = {};

  @observable fieldsDef = {};

  @observable requestInProgress = {
    fetchCampaign: false,
    updateCampaign: false,
    createCampaign: false,
    deleteCampaign: false,
    startCampaign: false,
    eventMetadata: false,
    fetchOptions: false,
    sendTestEmail: false,
  };

  @observable errors = {};

  @action.bound async fetchCampaignData(id) {
    this.requestInProgress.fetchCampaign = true;
    const { setFieldsData } = stores.forms.campaignForm;
    const setFieldsDataEmail = stores.forms.channelEmailForm.setFieldsData;
    const setFieldsDataMessage = stores.forms.channelMessengerForm.setFieldsData;
    try {
      const response = await fetchCampaignData(id);
      runInAction(() => {
        this.campaign = response.data;
      });
      setFieldsData(formatCampaignFormData(this.campaign));
      if (this.campaign.channel === 'Email') {
        setFieldsDataEmail(this.campaign.message);
      } else if (this.campaign.channel) {
        setFieldsDataMessage(this.campaign.message);
      }
    } catch {
      notifier.error(text.FETCH_CAMPAIGN_FAILED);
    } finally {
      runInAction(() => {
        this.requestInProgress.fetchCampaign = false;
      });
    }
  }

  @action.bound async updateCampaign(id, { history, status }) {
    this.requestInProgress.updateCampaign = true;
    const payload = formatCampaignPayload(this.queryBuilder.queries, status);
    try {
      await updateCampaign(id, payload);
      notifier.success(text.UPDATE_SUCCESS);
      history.push('/marketing/campaigns');
    } catch (e) {
      if (e?.response?.data) setErrors(e.response.data);
      notifier.error(text.UPDATE_FAILED);
    } finally {
      runInAction(() => {
        this.requestInProgress.updateCampaign = false;
      });
    }
  }

  @action.bound async createCampaign({ history, status }) {
    this.requestInProgress.createCampaign = true;
    const payload = formatCampaignPayload(this.queryBuilder.queries, status);
    const SUCCESS_MESSAGE = status === 'Draft' ? text.CREATE_DRAFT_SUCCESS : text.CREATE_SUCCESS;
    try {
      await createCampaign(payload);
      notifier.success(SUCCESS_MESSAGE);
      history.push('/marketing/campaigns');
    } catch (e) {
      if (e?.response?.data) setErrors(e.response.data);
      notifier.error(text.CREATE_FAILED);
    } finally {
      runInAction(() => {
        this.requestInProgress.createCampaign = false;
      });
    }
  }

  @action.bound async startCampaign({ history, campaignId }) {
    this.requestInProgress.startCampaign = true;
    try {
      await startCampaign(campaignId);
      notifier.success(text.START_SUCCESS);
      history.push('/marketing/campaigns');
    } catch (e) {
      if (e?.response?.data) setErrors(e.response.data);
      notifier.error(text.START_FAILED);
    } finally {
      runInAction(() => {
        this.requestInProgress.startCampaign = false;
      });
    }
  }

  @action.bound async fetchCampaignsOptions() {
    this.requestInProgress.fetchOptions = true;
    try {
      const response = await fetchCampaignsOptions();
      runInAction(() => {
        this.fieldsDef = response.data.actions.GET;
      });
    } catch {
      notifier.error(text.OPTIONS_FAILED);
    } finally {
      runInAction(() => {
        this.requestInProgress.fetchOptions = false;
      });
    }
  }

  @action.bound async fetchEventMetadata(triggerEventId) {
    this.requestInProgress.eventMetadata = true;
    try {
      const response = await fetchEventDefinitionData(triggerEventId);
      const { payload, old_payload } = response.data.template_properties;
      runInAction(() => {
        this.queryBuilder.setFields({});
        this.queryBuilder.setFieldsMetadata(
          formatEventNestedKeys({
            payload,
            old_payload,
          }),
          formatEventLabel
        );
      });
    } catch (e) {
      runInAction(() => {
        this.errors.eventMetadata = e.data;
      });
    } finally {
      const { goal_event_type, goal_query } = this.campaign;
      if (goal_event_type && goal_event_type.id === triggerEventId && goal_query) {
        this.queryBuilder.setQueries(goal_query);
      } else if (goal_event_type && goal_event_type.id !== triggerEventId) {
        this.queryBuilder.resetQueries();
      }
      runInAction(() => {
        this.requestInProgress.eventMetadata = false;
      });
    }
  }

  @action.bound async sendTestEmail() {
    this.requestInProgress.sendTestEmail = true;
    const { channel } = stores.forms.campaignForm.data;
    const { message_body } = stores.forms.channelMessengerForm.data;
    const { data } = stores.forms.channelEmailForm;
    const payload = {
      channel: channel.value,
      ...(channel.value === 'Email' && { email_message: { ...data } }),
      ...(channel.value !== 'Email' && { messanger_message: { message_body } }),
    };
    try {
      await sendTestEmail(payload);
      notifier.info(text.TEST_MESSAGE_SUCCESS);
    } catch {
      notifier.error(text.TEST_MESSAGE_FAILED);
    } finally {
      runInAction(() => {
        this.requestInProgress.sendToEmail = false;
      });
    }
  }

  @action.bound resetForm() {
    stores.forms.channelEmailForm.resetFieldsData();
    stores.forms.campaignForm.resetFieldsData();
    stores.forms.channelMessengerForm.resetFieldsData();
    this.resetCampaign();
    this.queryBuilder.resetQueries();
    this.fieldsDef = {};
  }

  @action.bound resetCampaign() {
    this.campaign = {};
  }

  @computed get updateCreateInProgress() {
    return this.requestInProgress.createCampaign || this.requestInProgress.updateCampaign;
  }

  // eslint-disable-next-line class-methods-use-this
  @computed get hasErrors() {
    return (
      !isEmpty(stores.forms.campaignForm.fieldErrors)
      || !isEmpty(stores.forms.channelEmailForm.fieldErrors)
      || !isEmpty(stores.forms.channelMessengerForm.fieldErrors)
    );
  }
}

function prefixZero(num) {
  if (num < 10) return `0${num}`;
  return num;
}

function formatTimeValue(value, unit) {
  if (value && unit === 'hour') {
    const hours = value > 23 ? +value % 24 : +value;
    const days = +value - hours > 0 ? (+value - hours) / 24 : 0;
    return `${prefixZero(days)} ${prefixZero(hours)}:00:00`;
  }
  if (value && unit === 'day') {
    return `${prefixZero(value)} 00:00:00`;
  }
  return null;
}

function parseHour(value) {
  if (!value) return null;
  const time = value.split(' ');
  /*
    hours can be in 'DD HH:mm:ss` and `HH:mm:ss` format
    so we have to do a check and sum hours to integer
  */
  if (time.length === 2) {
    try {
      const [day, hourMinutesSeconds] = time;
      const hours = +hourMinutesSeconds.split(':')[0];
      return +day * 24 + hours;
    } catch {
      return '00 00:00:00';
    }
  }
  const [hourMinutesSeconds] = time;
  return +hourMinutesSeconds.split(':')[0];
}

function formatCampaignPayload(queries, status) {
  const { data } = stores.forms.campaignForm;
  const messengerData = stores.forms.channelMessengerForm.data;
  const emailData = stores.forms.channelEmailForm.data;
  return {
    ...formatPayload(data),
    status,
    goal_attribution_window: formatTimeValue(data.goal_attribution_window, 'hour'),
    duration: formatTimeValue(data.duration, 'day'),
    goal_query: !isEmpty(queries.rules) ? formatQueryRulesArrayValues(queries) : null,
    ...(data.channel
      && data.channel.value === 'Email' && { email_message: { ...emailData, body: emailData.body } }),
    ...(data.channel
      && data.channel.value !== 'Email' && {
      messanger_message: { message_body: messengerData.message_body },
    }),
  };
}

function setErrors(errorData) {
  const { setFieldsErrors, resetFieldError } = stores.forms.campaignForm;
  setFieldsErrors(errorData);
  if (errorData.email_message) {
    resetFieldError('email_message');
    stores.forms.channelEmailForm.setFieldsErrors(errorData.email_message);
  }
  if (errorData.messanger_message) {
    resetFieldError('messanger_message');
    stores.forms.channelMessengerForm.setFieldsErrors(errorData.messanger_message);
  }
}

function formatCampaignFormData(data) {
  const {
    channel,
    send_to_type,
    send_to_segment,
    goal_event_type,
    goal_attribution_window,
    duration,
  } = data;
  return {
    ...data,
    channel: { value: channel, display_name: channel },
    send_to_type: {
      value: send_to_type,
      display_name: send_to_type,
    },
    send_to_segment: {
      value: send_to_segment?.id,
      display_name: send_to_segment?.name,
    },
    goal_event_type: {
      ...goal_event_type,
      value: goal_event_type?.id,
      display_name: goal_event_type?.name,
    },
    goal_attribution_window: parseHour(goal_attribution_window),
    duration: +duration?.split(' ')[0],
  };
}
export { parseHour };
