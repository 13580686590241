import GridStore from 'App/components/grid/GridStore';
import FiltersStore from 'App/components/Filters/FiltersStore';

export default {
  users: new GridStore(new FiltersStore()),
  contacts: new GridStore(new FiltersStore()),
  auditLogs: new GridStore(new FiltersStore()),
  trades: new GridStore(new FiltersStore()),
  positions: new GridStore(new FiltersStore()),
  contactOpenedPositions: new GridStore(new FiltersStore()),
  contactClosedPositions: new GridStore(new FiltersStore()),
  contactWallets: new GridStore(new FiltersStore()),
  contactWithdrawals: new GridStore(new FiltersStore()),
  contactPayments: new GridStore(new FiltersStore()),
  contactTrades: new GridStore(new FiltersStore()),
  wallets: new GridStore(new FiltersStore()),
  payments: new GridStore(new FiltersStore()),
  withdrawals: new GridStore(new FiltersStore()),
  events: new GridStore(new FiltersStore()),
  documentTypes: new GridStore(new FiltersStore()),
  roles: new GridStore(new FiltersStore()),
  cag: new GridStore(new FiltersStore()),
  emailLogs: new GridStore(new FiltersStore()),
  smsLogs: new GridStore(new FiltersStore()),
  whatsAppLogs: new GridStore(new FiltersStore()),
  callLogs: new GridStore(new FiltersStore()),
  savedFilters: new GridStore(new FiltersStore()),
  rules: new GridStore(new FiltersStore()),
  notificationPreferences: new GridStore(new FiltersStore()),
  webhooks: new GridStore(new FiltersStore()),
  webhookLogs: new GridStore(new FiltersStore()),
  segments: new GridStore(new FiltersStore()),
  webhookSingleLogs: new GridStore(new FiltersStore()),
  team: new GridStore(new FiltersStore()),
  campaigns: new GridStore(new FiltersStore()),
  recipients: new GridStore(new FiltersStore()),
  exportData: new GridStore(new FiltersStore()),
  segmentAudiences: new GridStore(new FiltersStore()),
};
