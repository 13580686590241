import { create } from 'axios';
import { notify } from 'tc-biq-design-system';
import store from 'App/rootStore';

const HOST = PROD_HOST || DEMO_HOST;

export const client = create({
  baseURL: `${HOST}${APP_ROOT}`,
});

const attachToken = async (config) => {
  // NOTE: Because the Auth0 function for getting the token can only be
  // retrieved through a react hook, we use that function from the store.
  // The function in the store is set in `AppRouter` component.
  const token = await store.loginStore.getToken();
  return {
    ...config,
    headers: {
      Authorization: (token && `Bearer ${token}`) || undefined,
    },
  };
};

client.interceptors.request.use(attachToken);
client.interceptors.response.use(
  response => response,
  async (error) => {
    if (error.response?.data?.non_field_errors) {
      error.response.data.non_field_errors.forEach(nonFieldError => notify.error({ title: 'Error', content: nonFieldError }));
    }
    return Promise.reject(error);
  }
);

export default client;
