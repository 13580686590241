import React from 'react';
import { string } from 'prop-types';
import { Pill } from 'tc-biq-design-system';

export const statePillTypeMap = {
  Review: 'status02',
  Pending: 'neutral',
  Rejected: 'status04',
  Full: 'status01',
  Demo: 'primary',
  Limited: 'status03',
  Blacklist: 'status04',
  Archived: 'status04',
  Lead: 'brand',
  Incomplete: 'neutral',
  'KYC checks in progress': 'status02',
  Waitlisted: 'status03',
  'Unsuitable Demo': 'neutral',
  'Unsuitable Demo Refer': 'neutral',
  'Pending Email Verification': 'neutral',
  'Pending Sms Verification': 'neutral',
  Unsuitable: 'neutral',
  'Unsuitable Refer': 'neutral',
  Alert: 'status01',
  Excluded: 'status04',
  Success: 'status01',
  Opened: 'status01',
  Succeeded: 'status01',
  Failed: 'status04',
  Closed: 'status04',
  Connected: 'status01',
  'No anwser': 'neutral',
  Busy: 'status04',
  'Left voice message': 'status01',
  'Wrong number': 'status04',
  'Manually logged': 'status03',
  Draft: 'primary',
  Ongoing: 'status01',
  Cancelled: 'status04',
  Finished: 'neutral',
  Processing: 'primary',
};

const propTypes = {
  value: string.isRequired,
};

const StatusCell = ({ value }) => (
  value ? <Pill type={statePillTypeMap[value] || 'neutral'}>{value}</Pill> : <div>-</div>
);

StatusCell.propTypes = propTypes;
export default StatusCell;
