import { observable, action } from 'mobx';
// NOTE: Namespace defined on the Auth0 that's used on the ID tokens to
// retrieve additional attributes.
const AUTH0_ID_TOKEN_NAMESPACE = 'https://infuse.trdcr.tech/';
export default class LoginStore {
  @observable requestSuccess = false;

  @observable user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : {};

  @observable getToken = null;

  @action.bound setUserData(auth0_user) {
    const { sub, ...data } = auth0_user;
    // Extracts ID of the user in Infuse system by parsing auth0 format.
    const id = Number(sub.split('|')[1]);
    const team = data[`${AUTH0_ID_TOKEN_NAMESPACE}team`];
    const user = { id, team, ...data };
    this.user = user;
    localStorage.setItem('user', JSON.stringify(user));
  }

  @action.bound setGetTokenFunc(func) {
    this.getToken = func;
  }
}
