import { observable, action } from 'mobx';

export default class OverlayStore {
  @observable overlay = {}

  @action.bound open(key, data = {}) {
    this.overlay = {
      [key]: true,
      parameters: data,
    };
  }

  @action.bound close(key) {
    this.overlay = {
      [key]: false,
      parameters: {},
    };
  }
}
