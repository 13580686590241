import React from 'react';
import { Spinner, Space } from 'tc-biq-design-system';
import classNames from 'classnames';
import { bool } from 'prop-types';

import './LoadingPage.scss';

const propTypes = {
  fullScreen: bool,
};

const defaultProps = {
  fullScreen: false,
};

const LoadingPage = ({ fullScreen }) => (
  <div className={classNames('fiq-loading-page', { 'fiq-loading-page__full-screen': fullScreen })}>
    <Spinner size="large" />
    <Space size={12} />
    <div className="tc-heading-l">Loading... </div>
  </div>
);

LoadingPage.propTypes = propTypes;
LoadingPage.defaultProps = defaultProps;

export default LoadingPage;
