const extractDisplayName = (data) => {
  if (!data) return '-';
  const { first_name, last_name, email, name, provider, display_name, label } = data;
  if (display_name) return display_name;
  if (first_name || last_name) return `${first_name} ${last_name}`;
  if (email) return email;
  if (name) return name;
  if (label) return label;
  if (provider) return `${provider[0].toUpperCase()}${provider.substring(1)}`;
  if (typeof data === 'string' || !Number.isNaN(+data)) return data;
  return 'N/A';
};

export default extractDisplayName;
