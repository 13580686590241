import { action, observable, computed } from 'mobx';
import { cloneDeep, omit, isEmpty } from 'lodash';

import errorFormatter from 'App/services/utilities/errorFormatter';

const ERROR_MESSAGES = {
  required: 'This field is required',
  invalidEmail: 'Please enter valid email address',
};

class FieldRendererStore {
  @observable data = {};

  @observable fieldErrors = {};

  @action.bound updateFieldValue(key, value) {
    this.resetFieldError(key);
    this.data[key] = value;
  }

  @action.bound setFieldsData(data) {
    this.data = cloneDeep(data);
  }

  @action.bound setFieldsErrors(errors) {
    this.fieldErrors = errorFormatter(errors);
  }

  @action.bound setFieldError(key, value) {
    this.fieldErrors = { ...this.fieldErrors, [key]: value };
  }

  @action.bound resetFieldError(key) {
    this.fieldErrors = omit(this.fieldErrors, [key]);
  }

  @action.bound resetFieldsData() {
    this.data = {};
    this.fieldErrors = {};
  }

  @action.bound validateField(key, validity) {
    if (!validity.valid) {
      if (validity.valueMissing) this.setFieldError(key, ERROR_MESSAGES.required);
      if (validity.typeMismatch && key === 'email') this.setFieldError(key, ERROR_MESSAGES.invalidEmail);
    }
  }

  @computed get hasErrors() {
    return !isEmpty(this.fieldErrors);
  }
}

export default FieldRendererStore;
