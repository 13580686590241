/* eslint-disable max-len */
import { observable, action, runInAction } from 'mobx';
import { find, sortBy } from 'lodash';

import errorFormatter from 'App/services/utilities/errorFormatter';

import RolesService from 'Settings/Sections/Roles/services/rolesService';
import PERMISSIONS_FIELD_TYPES from 'App/enums/permissionsFieldTypes';
import { notifier } from 'tc-biq-design-system';

export default class RolesStore {
  constructor(manageUsersStore) {
    this.manageUsers = manageUsersStore;
  }

  @observable rolesData = [];

  @observable filteredData = [];

  payload = {};

  @observable fields = {
    name: '',
    searchTerm: '',
    accordions: { allVisible: false },
  };

  @observable allPermissions = {
    read: false,
    create: false,
    update: false,
    delete: false,
    export: false,
  }

  @observable requestInProgress = {
    fetchData: false,
    fetchUsers: false,
    fetchBindUsers: false,
    addEditRole: false,
    deleteRole: false,
    activeUserId: 0,
  };

  @observable errors = {
    fetchData: null,
    fetchUsers: null,
    fetchBindUsers: null,
    addEditRole: null,
    addRemoveRoleBinding: null,
  };

  @action.bound updateFieldValue(key, value) {
    this.fields[key] = value;
    if (key === 'searchTerm') this.filterRolesData(value);
  }

  @action.bound async fetchRolesData(id) {
    this.requestInProgress.fetchData = true;
    this.errors.fetchData = null;
    try {
      const response = await RolesService.fetchPermissionMetadata();
      let rolePermissions = null;
      if (id) {
        const roleResponse = await RolesService.fetchRoleData(id);
        rolePermissions = roleResponse.data.permissions;
        this.updateFieldValue('name', roleResponse.data.name);
      }
      runInAction(() => {
        this.rolesData = formatTableData(sortBy(response.data, ['content_type']), rolePermissions);
        this.filteredData = formatTableData(
          sortBy(response.data, ['content_type']),
          rolePermissions
        );
        this.requestInProgress.fetchData = false;
      });
    } catch (e) {
      runInAction(() => {
        this.errors.fetchData = e.data;
        this.requestInProgress.fetchData = false;
      });
    }
  }

  @action.bound updateFieldPermission(endpointName, field, permission) {
    this.filteredData = this.filteredData.slice();
    const endpoint = find(this.filteredData, { name: endpointName });
    if (endpoint) {
      const currentFieldPermissions = find(endpoint.fieldPermissions, { id: field });
      Object.keys(currentFieldPermissions).forEach((key) => {
        if (typeof currentFieldPermissions[key] === 'boolean') {
          currentFieldPermissions[key] = key === permission;
        }
      });
    }
  }

  @action.bound updateAllEndpoints(allEndpoints, permission, value) {
    this.allPermissions[permission] = value;
    allEndpoints.forEach(endpointName => this.updateEndpointPermission(endpointName, permission, value));
    this.filteredData = this.filteredData.slice();
  }

  @action.bound updateEndpointPermission(endpointName, permission, value) {
    const endpoint = find(this.filteredData, { name: endpointName });
    endpoint.endpointPermissions[permission] = value;
    this.checkSelectedPermissions(endpoint);
  }

  @action.bound checkSelectedPermissions(endpoint) {
    const { read, update, create } = endpoint.endpointPermissions;
    const { name, fieldPermissions, readOnlyFields } = endpoint;
    if (!read && (update || create)) {
      fieldPermissions.forEach((field) => {
        if (!readOnlyFields.includes(field.id)) {
          this.updateFieldPermission(name, field.id, PERMISSIONS_FIELD_TYPES.writeFields);
        }
      });
    }
    if (read && !update && !create) {
      fieldPermissions.forEach(field => this.updateFieldPermission(name, field.id, PERMISSIONS_FIELD_TYPES.readFields));
    }
    if (!read && !update && !create) {
      fieldPermissions.forEach(field => this.updateFieldPermission(name, field.id, PERMISSIONS_FIELD_TYPES.hiddenFields));
    }
  }

  @action.bound filterRolesData(term) {
    if (!term) {
      this.filteredData = formatTableData(this.rolesData);
    } else {
      this.filteredData = this.rolesData.filter(role => role.name.toLowerCase().includes(term.toLowerCase()));
    }
  }

  @action.bound async addEditRole({ onSuccess, id, isClone }) {
    this.requestInProgress.addEditRole = true;
    this.errors.addEditRole = null;
    const payload = {
      name: this.fields.name,
      permissions: formatPermissions(this.filteredData),
    };
    try {
      if (id && !isClone) {
        await RolesService.editRole(id, { id, ...payload });
      } else {
        await RolesService.addRole(payload);
      }
      runInAction(() => {
        this.requestInProgress.addEditRole = false;
        if (onSuccess) onSuccess();
      });
    } catch (e) {
      runInAction(() => {
        if (e.response && e.response.data.detail) {
          notifier.error(e.response.data.detail);
        } else {
          this.errors.addEditRole = errorFormatter(e.response.data);
        }
        this.requestInProgress.addEditRole = false;
      });
    }
  }

  @action.bound async deleteRole(id) {
    this.requestInProgress.deleteRole = true;
    try {
      await RolesService.deleteRole(id);
      runInAction(() => {
        this.requestInProgress.deleteRole = false;
      });
    } catch (e) {
      runInAction(() => {
        this.requestInProgress.deleteRole = false;
      });
    }
  }
}

function formatTableData(data, predefinedData) {
  return data.map((endpoint) => {
    const endpointValues = find(predefinedData, { content_type: endpoint.content_type });
    return {
      ...endpoint,
      endpointPermissions: {
        read: (endpointValues && endpointValues.read) || false,
        create: (endpointValues && endpointValues.create) || false,
        update: (endpointValues && endpointValues.update) || false,
        delete: (endpointValues && endpointValues.delete) || false,
        export: (endpointValues && endpointValues.export) || false,
      },
      fieldPermissions: endpoint.fields.map(field => ({
        name: endpoint.name,
        id: field,
        field,
        read_fields: endpointValues ? endpointValues.read_fields.includes(field) : false,
        write_fields: endpointValues ? endpointValues.write_fields.includes(field) : false,
        hidden_fields: endpointValues ? endpointValues.hidden_fields.includes(field) : true,
      })),
      readOnlyFields: endpoint.read_only_fields,
    };
  });
}

function extractFields(fieldPermissions, key) {
  return fieldPermissions.filter(field => field[key]).map(field => field.id);
}

function formatPermissions(data) {
  const permissions = [];
  data.forEach((endpoint) => {
    permissions.push({
      content_type: endpoint.content_type,
      ...endpoint.endpointPermissions,
      write_fields: extractFields(endpoint.fieldPermissions, PERMISSIONS_FIELD_TYPES.writeFields),
      read_fields: extractFields(endpoint.fieldPermissions, PERMISSIONS_FIELD_TYPES.readFields),
      hidden_fields: extractFields(endpoint.fieldPermissions, PERMISSIONS_FIELD_TYPES.hiddenFields),
    });
  });
  return permissions;
}
