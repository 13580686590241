import React from 'react';
import { HyperLink } from 'tc-biq-design-system';
import { Link } from 'react-router-dom';

import './RouteCell.scss';
// eslint-disable-next-line
const RouteCell = href => ({ data, value, hrefValue }) => {
  return (
    <HyperLink>
      <Link to={`${href}${hrefValue || value}`}>{value}</Link>
    </HyperLink>
  );
};

export default RouteCell;
