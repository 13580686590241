import http from 'App/services/http';

const fetchSegment = id => http.get(`/automations/segments/${id}`);

const patchSegment = (id, payload) => http.patch(`/automations/segments/${id}/`, payload);

const createSegment = payload => http.post('/automations/segments/', payload);

const fetchFieldsDef = () => http.options('/automations/segments/');

const deleteSegment = id => http.delete(`/automations/segments/${id}`);

const fetchSegmentMetadata = () => http.get('/automations/segments/attributes-metadata/');

const createAudience = (segment_id, payload) => http.post(`/automations/segments/${segment_id}/audiences/`, payload);

const pauseAudience = (segment_id, audience_id) => http.post(`/automations/segments/${segment_id}/audiences/${audience_id}/pause/`);

const resumeAudience = (segment_id, audience_id) => http.post(`/automations/segments/${segment_id}/audiences/${audience_id}/resume/`);

const deleteAudience = (segment_id, audience_id) => http.delete(`/automations/segments/${segment_id}/audiences/${audience_id}/`);

export {
  deleteSegment,
  patchSegment,
  fetchSegment,
  createSegment,
  fetchFieldsDef,
  fetchSegmentMetadata,
  createAudience,
  pauseAudience,
  resumeAudience,
  deleteAudience,
};
