import http from 'App/services/http';

const toggleActiveState = (id, value) => http.patch(`/events/${id}/`, { is_activity_visible: value });

const fetchEventDefinitionData = id => http.get(`/events/${id}/`);

const fetchEventDefinitionFields = id => http.options(`/events/${id}/`);

const editEventDefinition = (id, payload) => http.patch(`/events/${id}/`, payload);

export {
  toggleActiveState,
  fetchEventDefinitionData,
  fetchEventDefinitionFields,
  editEventDefinition,
};
