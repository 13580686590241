import get from 'lodash/get';
import extractNestedFields from 'App/services/utilities/extractNestedFields';

export {
  getAffectedObject,
  removeAffectedCondition,
  formatEventLabel,
  formatEventNestedKeys,
  formatFields,
};

function getAffectedObject(target, identifier) {
  const affectedObject = get(target, getDataPath(identifier));
  return affectedObject;
}

function removeAffectedCondition(target, identifier) {
  const temp = target.slice();
  if (identifier.length === 1) {
    temp.splice(identifier[0], 1);
  } else {
    const parentIdentifier = [...identifier];
    const conditionIndex = parentIdentifier.pop();
    const parentDataPath = getDataPath(parentIdentifier);
    const parentCondition = get(temp, parentDataPath);
    parentCondition.rules.splice(conditionIndex, 1);
  }
  return temp;
}

function getDataPath(identifier) {
  return identifier
    .map((position, index) => {
      if (index + 1 === identifier.length) return `[${position}]`;
      return `[${position}].rules`;
    })
    .join('');
}

function removeAndFormat(label, stringMatch) {
  return label
    .replace(stringMatch, '')
    .split('_')
    .map((string, index) => {
      if (index === 0) {
        return string.charAt(0).toUpperCase() + string.substring(1);
      }
      return string;
    })
    .join(' ');
}

function formatEventLabel(label) {
  if (label.includes('Payload ')) {
    return removeAndFormat(label, 'Payload ');
  }
  if (label.includes(' payload')) {
    return removeAndFormat(label, ' payload');
  }
  return label;
}

function formatEventNestedKeys(fields) {
  const temp = {};
  Object.keys(fields).forEach((prefixKey) => {
    const childrenFields = extractNestedFields(fields[prefixKey].children);
    Object.keys(childrenFields).forEach((fieldKey) => {
      temp[`${prefixKey}.${fieldKey.split('__').join('.')}`] = childrenFields[fieldKey];
    });
  });
  return temp;
}

function formatLabelFromKey(key) {
  return key
    .split('.')
    .join('_')
    .toLowerCase()
    .split('_')
    .map((string, index) => {
      if (index === 0) {
        return string.charAt(0).toUpperCase() + string.substring(1);
      }
      return string;
    })
    .join(' ');
}

function formatFields(fieldsDef, labelFormatter) {
  const fields = {};
  Object.keys(fieldsDef).forEach((key) => {
    fields[key] = {
      ...fieldsDef[key],
      name: key,
      id: key,
      label: labelFormatter ? labelFormatter(formatLabelFromKey(key)) : formatLabelFromKey(key),
    };
  });
  return fields;
}
