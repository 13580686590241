import http from 'App/services/http';

const fetchCampaignData = id => http.get(`marketing/campaigns/${id}/`);

const createCampaign = payload => http.post('/marketing/campaigns/', payload);

const updateCampaign = (id, payload) => http.put(`/marketing/campaigns/${id}/`, payload);

const fetchCampaignsOptions = () => http.options('/marketing/campaigns/');

const startCampaign = id => http.post(`/marketing/campaigns/${id}/start/`);

const endCampaign = id => http.post(`/marketing/campaigns/${id}/end/`);

const deleteCampaign = id => http.delete(`/marketing/campaigns/${id}/`);

const sendTestEmail = payload => http.post('/marketing/campaigns/send-test-communication/', payload);

const fetchContactAttributes = () => http.get('/marketing/campaigns/contact-attributes/');

const fetchKPIs = id => http.get(`/marketing/campaigns/${id}/stats/`);

const fetchCampaignRecipients = id => http.get(`/marketing/campaigns/${id}/memberships/`);

export {
  fetchCampaignData,
  createCampaign,
  updateCampaign,
  sendTestEmail,
  fetchCampaignsOptions,
  startCampaign,
  endCampaign,
  deleteCampaign,
  fetchContactAttributes,
  fetchKPIs,
  fetchCampaignRecipients,
};
