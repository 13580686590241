const formatPayload = (data) => {
  const payload = {};
  if (!data) return null;
  Object.keys(data).forEach((key) => {
    if (Array.isArray(data[key])) {
      payload[key] = data[key].map((item) => {
        if (typeof item === 'object' && item !== null) {
          return item.id || item.value;
        }
        return item;
      });
    } else if (typeof data[key] === 'object' && data[key] !== null) {
      payload[key] = data[key].value;
    } else if (key !== 'avatar') {
      payload[key] = data[key];
    }
  });
  return payload;
};

export default formatPayload;
