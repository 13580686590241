import { action, observable, runInAction } from 'mobx';
import { notifier } from 'tc-biq-design-system';

import {
  fetchTradeData,
  fetchPaymentData,
  fetchWalletData,
  fetchLedgerData,
  fetchWalletBalance,
  fetchOrderData,
  fetchWithdrawalData,
  fetchPositionData,
} from 'Transactions/services/transactionsService';
import { parseParamString } from 'App/components/Filters/filterStoreUtils';

export default class TransactionsStore {
  @observable tradeData = {};

  @observable paymentData = {};

  @observable withdrawalData = {};

  @observable orderData = [];

  @observable walletData = {};

  @observable ledgerData = [];

  @observable positionData = {};

  @observable requestInProgress = {
    ledgerData: false,
  };

  @observable hasMore = true;

  @observable ledgerQuery = {
    cursor: '',
    limit: 20,
  };

  @observable walletBalanceData = {};

  @action.bound async fetchTradeData(id) {
    const response = await fetchTradeData(id);
    runInAction(() => {
      this.tradeData = response.data;
    });
  }

  @action.bound async fetchPaymentData(id) {
    const response = await fetchPaymentData(id);
    runInAction(() => {
      this.paymentData = response.data;
    });
  }

  @action.bound async fetchWithdrawalData(id) {
    const response = await fetchWithdrawalData(id);
    runInAction(() => {
      this.withdrawalData = response.data;
    });
  }

  @action.bound async fetchOrderData(id) {
    const response = await fetchOrderData(id);
    runInAction(() => {
      this.orderData = response.data;
    });
  }

  @action.bound async fetchWalletData(id) {
    try {
      const response = await fetchWalletData(id);
      runInAction(() => {
        this.walletData = response.data;
      });
    } catch (err) {
      notifier.error('Failed to fetch wallet data');
    }
  }

  @action.bound async fetchPositionData(id) {
    const response = await fetchPositionData(id);
    runInAction(() => {
      this.positionData = response.data;
    });
  }

  @action.bound async fetchLedgerData(id, isOnScroll) {
    this.requestInProgress.ledgerData = true;
    const response = await fetchLedgerData(id, this.ledgerQuery);
    const { next, results } = response.data;
    const params = parseParamString(next);
    runInAction(() => {
      this.hasMore = !!next;
      this.ledgerQuery.cursor = params.cursor;
      this.ledgerData = isOnScroll ? [...this.ledgerData, ...results] : response.data.results;
      this.requestInProgress.ledgerData = false;
    });
  }

  @action.bound resetData() {
    this.ledgerData = [];
    this.orderData = [];
    this.ledgerQuery.cursor = '';
    this.tradeData = {};
    this.walletData = {};
    this.paymentData = {};
    this.positionData = {};
  }

  @action.bound async fetchWalletBalance(id) {
    try {
      const response = await fetchWalletBalance(id);
      runInAction(() => {
        this.walletBalanceData = response.data;
      });
    } catch (err) {
      // TODO: Show different error if trading provider isn't configured.
      notifier.error('Failed to retrieve wallet balance');
      runInAction(() => {
        this.walletBalanceData = {
          available: '-',
          reserved: '-',
          total: '-',
        };
      });
    }
  }
}
