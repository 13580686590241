import React from 'react';
import { I18nProvider } from '@lingui/react';
import { any } from 'prop-types';
import { inject, observer } from 'mobx-react';

import en from 'locales/en/messages';
import fr from 'locales/fr/messages';
import { Auth0Provider } from '@auth0/auth0-react';
import AppRouter from './AppRouter';

import 'tc-biq-design-system/build/index.css';
import './App.scss';

const catalogs = {
  en,
  fr,
};

const propTypes = {
  translateStore: any.isRequired,
};

const App = inject('translateStore')(
  observer(({ translateStore }) => (
    <I18nProvider language={translateStore.lang} catalogs={catalogs}>
      <Auth0Provider
        domain={AUTH0_DOMAIN}
        clientId={AUTH0_CLIENT_ID}
        redirectUri={window.location.origin}
        audience={AUTH0_AUDIENCE}
        useRefreshTokens
        cacheLocation="localstorage"
        scope="all"
      >
        <AppRouter />
      </Auth0Provider>
    </I18nProvider>
  ))
);

App.wrappedComponent.propTypes = propTypes;

export default App;
