import { action, observable, runInAction } from 'mobx';
import { notifier } from 'tc-biq-design-system';
import { convertToRaw } from 'draft-js';

import formatPayload from 'App/services/utilities/formatPayload';
import stores from 'App/rootStore';
import {
  fetchContactData,
  fetchContactInfoLayout,
  fetchContactOptions,
  editContactBasicInfo,
  sendEmail as sendEmailApi,
  sendSms,
  assignManager,
  sendWhatsApp,
  fetchEmailLog,
  logCall,
} from 'Contacts/services/contactsService';
import convertRawValueToHtml from 'App/services/utilities/draftJsUtils';
import * as omit from 'lodash/omit';

const text = {
  EDIT_DETAILS_SUCCESS: 'Edited details successfully',
  EDIT_DETAILS_FAILED: 'Failed to edit details',
  SEND_EMAIL_SUCCESS: 'Email sent successfully',
  SEND_EMAIL_FAILED: 'Failed to send an email!',
  SEND_SMS_SUCCESS: 'SMS sent successfully',
  SEND_SMS_FAILED: 'Failed to send SMS!',
  SEND_WHATS_APP_SUCCESS: 'WhatsApp message sent successfully',
  SEND_WHATS_APP_FAILED: 'Failed to send WhatsApp message!',
  ASSIGN_MANAGER_SUCCESS: 'Successfully assigned manager!',
  UNASSING_MANAGER_SUCCESS: 'Successfully unassigned manager!',
  LOAD_EMAIL_FAILED: 'Failed to load email data!',
  LOG_CALL_SUCCESS: 'Successfully logged call',
  LOG_CALL_FAILED: 'Failed to log call',
};

export default class ContactStore {
  @observable contactData = {};

  @observable contactOptions = {};

  @observable contactInfoLayout = {};

  @observable requestInProgress = {
    contactData: false,
    editBasicInfo: false,
    sendEmail: false,
    sendSms: false,
    sendWhatsApp: false,
    assignManager: false,
    getEmailLog: false,
    logCall: false,
  };

  @observable errors = {
    contactData: null,
    editBasicInfo: null,
    sendEmail: null,
    sendSms: null,
    sendWhatsApp: null,
    assignManager: null,
    logCall: null,
  };

  @action.bound async fetchContactData(id) {
    this.requestInProgress.contactData = true;
    try {
      const response = await Promise.all([
        fetchContactData(id),
        fetchContactInfoLayout(),
        fetchContactOptions(),
      ]);
      runInAction(() => {
        this.contactData = response[0].data;
        this.contactInfoLayout = response[1].data;
        this.contactOptions = response[2].data.actions.GET;
        this.requestInProgress.contactData = false;
      });
    } catch (err) {
      runInAction(() => {
        this.errors.contactData = err;
        this.requestInProgress.contactData = false;
      });
    }
  }

  @action.bound async editBasicInfo() {
    this.requestInProgress.editBasicInfo = true;
    const { data, setFieldsErrors } = stores.forms.editBasicInfoForm;
    try {
      const { id } = this.contactData;
      const response = await editContactBasicInfo(id, formatPayload(data));
      runInAction(() => {
        this.requestInProgress.editBasicInfo = false;
        this.contactData = response.data;
        this.errors.editBasicInfo = null;
        notifier.success(text.EDIT_DETAILS_SUCCESS);
      });
    } catch (err) {
      setFieldsErrors(err.response.data);
      runInAction(() => {
        this.requestInProgress.editBasicInfo = false;
        this.errors.editBasicInfo = err.response.data;
      });
      notifier.error(text.EDIT_DETAILS_FAILED);
    }
  }

  @action.bound async sendEmail(files, isReplyTo, contactId) {
    // TODO: Update this method when API is ready
    const { id } = this.contactData;
    const { data, setFieldsErrors } = stores.forms.sendEmailForm;
    this.requestInProgress.sendEmail = true;
    const rawBodyState = convertToRaw(data.body.getCurrentContent());
    const omitedData = omit(data, isReplyTo ? ['subject'] : []);

    try {
      await sendEmailApi(
        contactId || id,
        {
          ...omitedData,
          body: convertRawValueToHtml(rawBodyState),
          email_inbox: data.email_inbox.value,
          cc: (data.cc || []).map(({ email, display_name }) => email || display_name),
          bcc: (data.bcc || []).map(({ email, display_name }) => email || display_name),
        },
        files
      );
      notifier.success(text.SEND_EMAIL_SUCCESS);
      runInAction(() => {
        this.requestInProgress.sendEmail = false;
        this.errors.sendEmail = null;
      });
    } catch (err) {
      if (err.response && err.response.data.detail) {
        notifier.error(err.response.data.detail);
      } else if (err.response && Array.isArray(err.response.data)) {
        notifier.error(err.response.data[0]);
      } else {
        notifier.error(text.SEND_EMAIL_FAILED);
        if (err.response && err.response.data) {
          setFieldsErrors(err.response.data);
        }
      }
      runInAction(() => {
        if (err.response && err.response.data) {
          this.errors.sendEmail = err.response.data;
        }
        this.requestInProgress.sendEmail = false;
      });
    }
  }

  @action.bound async sendSms() {
    const { id } = this.contactData;
    const { data, setFieldsErrors } = stores.forms.sendSmsForm;
    this.requestInProgress.sendSms = true;
    try {
      await sendSms(id, data);
      notifier.success(text.SEND_SMS_SUCCESS);
      runInAction(() => {
        this.requestInProgress.sendSms = false;
        this.errors.sendSms = null;
      });
    } catch (err) {
      if (err.response.data.detail) {
        notifier.error(err.response.data.detail);
      } else {
        notifier.error(text.SEND_SMS_FAILED);
        setFieldsErrors(err.response.data);
      }
      runInAction(() => {
        this.errors.sendSms = err.response.data;
        this.requestInProgress.sendSms = false;
      });
    }
  }

  @action.bound async sendWhatsApp() {
    const { id } = this.contactData;
    const { data, setFieldsErrors } = stores.forms.sendWhatsAppForm;
    this.requestInProgress.sendWhatsApp = true;
    try {
      await sendWhatsApp(id, data);
      notifier.success(text.SEND_WHATS_APP_SUCCESS);
      runInAction(() => {
        this.requestInProgress.sendWhatsApp = false;
        this.errors.sendWhatsApp = null;
      });
    } catch (err) {
      if (err.response.data.detail) {
        notifier.error(err.response.data.detail);
      } else {
        notifier.error(text.SEND_WHATS_APP_FAILED);
        setFieldsErrors(err.response.data);
      }
      runInAction(() => {
        this.errors.sendWhatsApp = err.response.data;
        this.requestInProgress.sendWhatsApp = false;
      });
    }
  }

  @action.bound async logCall() {
    this.requestInProgress.logCall = true;
    this.errors.logCall = null;
    const { data, setFieldsErrors } = stores.forms.logCall;
    try {
      await logCall(formatPayload(data));
      notifier.success(text.LOG_CALL_SUCCESS);
    } catch (e) {
      runInAction(() => {
        this.errors.logCall = e;
      });
      if (e.response && e.response.data) {
        setFieldsErrors(e.response.data);
      }
      notifier.error(text.LOG_CALL_FAILED);
    } finally {
      runInAction(() => {
        this.requestInProgress.logCall = false;
      });
    }
  }

  @action.bound async assignManagerToContact(managerId, managerKey) {
    const { id: contactId } = this.contactData;
    this.requestInProgress.assignManager = true;
    try {
      await assignManager(contactId, managerId, managerKey);
      this.fetchContactData(contactId);
      if (managerId) {
        notifier.success(text.ASSIGN_MANAGER_SUCCESS);
      } else {
        notifier.success(text.UNASSING_MANAGER_SUCCESS);
      }
    } catch (err) {
      this.errors.assignManager = err.response.data;
    } finally {
      runInAction(() => {
        this.requestInProgress.assignManager = false;
      });
    }
  }

  @action.bound async getEmailLog(id) {
    this.requestInProgress.getEmailLog = true;
    let res;

    try {
      res = await fetchEmailLog(id);
    } catch {
      notifier.error(text.LOAD_EMAIL_FAILED);
      return {};
    } finally {
      runInAction(() => {
        this.requestInProgress.getEmailLog = false;
      });
    }

    return res.data;
  }

  @action.bound resetContactData() {
    this.contactData = {};
  }
}
