import { action, observable, computed } from 'mobx';
import { flatten } from 'lodash';

import { hasAccess } from 'App/services/permissionsService';

const allItems = [
  {
    label: 'Activity stream',
    icon: 'ActivityStream',
    route: '/activity-stream',
    permission: 'events_activity',
  },
  { label: 'Tasks', icon: 'Tasks', route: '/tasks', permission: 'tasks_task' },
  {
    label: 'Contacts',
    icon: 'Client',
    route: '/contacts',
    permission: 'contacts_contact',
  },
  {
    label: 'Transactions',
    icon: 'Trade',
    subItems: [
      {
        label: 'Trades',
        route: '/transactions/trades',
        permission: 'finances_tradetransaction',
      },
      {
        label: 'Positions',
        route: '/transactions/positions',
        permission: 'finances_tradeposition',
      },
      {
        label: 'Wallets',
        route: '/transactions/wallets',
        permission: 'finances_wallet',
      },
      {
        label: 'Payments',
        route: '/transactions/payments',
        permission: 'finances_paymenttransaction',
      },
      {
        label: 'Withdrawals',
        route: '/transactions/withdrawals',
        permission: 'finances_withdrawrequest',
      },
    ],
  },
  {
    label: 'Communications',
    icon: 'SingleChat',
    subItems: [
      {
        label: 'Email Logs',
        route: '/communications/email-logs',
        permission: 'communications_emaillog',
      },
      {
        label: 'SMS Logs',
        route: '/communications/sms-logs',
        permission: 'communications_smslog',
      },
      {
        label: 'WhatsApp Logs',
        route: '/communications/whatsapp-logs',
        permission: 'communications_whatsapplog',
      },
      {
        label: 'Call Logs',
        route: '/communications/call-logs',
        permission: 'communications_calllog',
      },
    ],
  },
  {
    label: 'Marketing',
    icon: 'Marketing',
    subItems: [
      {
        label: 'Campaigns',
        route: '/marketing/campaigns',
        permission: 'marketing_campaign',
      },
    ],
  },
  {
    label: 'Automation',
    icon: 'Automation',
    subItems: [
      {
        label: 'Rules',
        route: '/automation/rules',
        permission: 'automations_rule',
      },
      {
        label: 'Segments',
        route: '/automation/segments',
        permission: 'automations_segment',
      },
    ],
  },
  {
    label: 'Settings',
    icon: 'Settings',
    route: '/settings',
    sections: [
      [
        {
          label: 'Users',
          icon: 'User',
          route: '/settings/users',
          permission: 'users_user',
        },
        {
          label: 'Teams',
          icon: 'Teams',
          route: '/settings/teams',
          permission: 'users_team',
        },
        {
          label: 'Roles',
          icon: 'Roles',
          route: '/settings/roles',
          permission: 'users_role',
        },
        {
          label: 'Contact Access Group',
          icon: 'Ib',
          route: '/settings/contact-access-groups',
          permission: 'contact_access_groups_contactaccessgroupbinding',
        },
      ],
      [
        {
          label: 'Audit logs',
          icon: 'Logs',
          route: '/settings/audit-logs',
          permission: 'audit_logs_auditlog',
        },
        {
          label: 'Events',
          icon: 'Action',
          route: '/settings/events',
          permission: 'events_eventtype',
        },
        {
          label: 'Saved filters',
          icon: 'Funnel',
          route: '/settings/saved-filters',
          permission: 'users_savedfilter',
        },
      ],
      [
        {
          label: 'Documents',
          icon: 'Duplicate',
          route: '/settings/document-types',
          permission: 'documents_documenttype',
        },
        {
          label: 'Webhooks',
          icon: 'Chain',
          route: '/settings/webhooks',
          permission: 'automations_rule',
        },
        {
          label: 'Integrations',
          icon: 'InternalTransaction',
          route: '/settings/integrations',
          permission: 'constance_config',
        },
      ],
    ],
  },
];

/*
 * checkNavigatorAccess function
 * @param items
 * @returns array of navItems that have permission
 * @descritption filters Navigation items per permissions in MainSidebar
 * Conditions:
 * - if navItem has route and permission;
 * - if navItem has subItems and at least one subItem has permission;
 * - if navItem has sections and if at least one section has item with permission;
 */

const checkNavigatorAccess = (items) => {
  const filteredItems = [];
  items.forEach((item) => {
    if (item.permission && hasAccess(item.permission, 'read')) {
      filteredItems.push(item);
    } else if (item.subItems) {
      const temp = { ...item };
      temp.subItems = temp.subItems.filter(subItem => hasAccess(subItem.permission, 'read'));
      if (temp.subItems.length > 0) filteredItems.push(temp);
    } else if (item.sections) {
      const temp = { ...item };
      temp.sections = temp.sections
        .slice()
        .map(section => section.filter(sectionItem => hasAccess(sectionItem.permission, 'read')));
      if (flatten(temp.sections).length > 0) filteredItems.push(temp);
    }
  });
  return filteredItems;
};

const getHomeRoute = (items) => {
  if (items[0].subItems) return items[0].subItems[0].route;
  if (items[0].route) return items[0].route;
  return '/';
};

export default class NavigatorStore {
  @observable allItems = [...allItems];

  @observable visibleItems = [];

  @observable homeRoute = '/';

  @action.bound filterVisibleItems() {
    this.visibleItems = checkNavigatorAccess(this.allItems);
    this.homeRoute = this.hasNavItems ? getHomeRoute(this.visibleItems) : '/no-permission';
  }

  @action.bound setHomeRoute(route) {
    this.homeRoute = route;
  }

  @computed get hasNavItems() {
    return !!this.visibleItems.length;
  }
}
