/* eslint-disable max-len */
import { observable, action, runInAction, computed } from 'mobx';

import isEmpty from 'lodash/isEmpty';
import {
  fetchCagData,
  fetchCagMetadata,
  addContactAccessGroup,
  deleteContactAccessGroup,
  editContactAccessGroup,
} from 'Settings/Sections/ContactAccessGroup/services/ContactAccessGroupService';
import { fetchContactOptions } from 'Contacts/services/contactsService';
import formatQueryRulesArrayValues from 'App/services/utilities/formatQueryRulesArrayValues';
import { notifier } from 'tc-biq-design-system';

export default class ContactAccessGroupStore {
  constructor(queryBuilderStore, manageUsersStore) {
    this.queryBuilder = queryBuilderStore;
    this.manageUsers = manageUsersStore;
  }

  @observable requestInProgress = {
    cagData: false,
    contactAccessGroupAction: false,
  };

  @observable errors = {
    cagData: null,
    cagName: null,
    contactAccessGroupAction: null,
  };

  // CAG Single Page observables
  @observable cagData = {};

  @observable fields = {};

  @observable cagName = '';

  @observable cagManagerVisiblity = false;

  // CAG Actions

  @action.bound async fetchCagMetadata() {
    this.requestInProgress.cagData = true;
    try {
      const [response, metadataResponse] = await Promise.all([
        fetchContactOptions(),
        fetchCagMetadata(),
      ]);
      runInAction(() => {
        this.fields = formatFields(response.data.actions.GET, metadataResponse.data);
        this.queryBuilder.setFields(this.fields);
        this.queryBuilder.setFieldsMetadata(metadataResponse.data);
        this.requestInProgress.cagData = false;
      });
    } catch (e) {
      runInAction(() => {
        this.requestInProgress.cagData = false;
      });
    }
  }

  @action.bound async fetchCagData(id) {
    this.requestInProgress.cagData = true;
    try {
      const response = await fetchCagData(id);
      const { query, name, is_accessible_to_managers_only } = response.data;
      runInAction(() => {
        this.cagManagerVisiblity = is_accessible_to_managers_only;
        this.cagData = response.data;
        if (query) {
          this.queryBuilder.setQueries(query);
        }
        this.cagName = name;
        this.requestInProgress.cagData = false;
      });
    } catch (e) {
      runInAction(() => {
        this.errors.cagData = e.response.data;
        this.requestInProgress.cagData = false;
      });
    }
  }

  @action.bound updateCagName({ target }) {
    this.errors.cagName = null;
    this.cagName = target.value;
  }

  @action.bound updateCagManagerVisibility({ target }) {
    this.cagManagerVisiblity = target.checked;
  }

  @action.bound async addEditContactAccessGroup(id, history) {
    this.requestInProgress.contactAccessGroupAction = true;
    const payload = {
      name: this.cagName,
      query: !isEmpty(this.queryBuilder.queries.rules)
        ? formatQueryRulesArrayValues(this.queryBuilder.queries)
        : null,
      is_accessible_to_managers_only: this.cagManagerVisiblity,
    };
    try {
      if (id) {
        await editContactAccessGroup(id, payload);
      } else {
        await addContactAccessGroup(payload);
      }
      runInAction(() => {
        this.requestInProgress.contactAccessGroupAction = false;
      });
      history.push('/settings/contact-access-groups');
    } catch (err) {
      runInAction(() => {
        if (err.response && err.response.data.detail) {
          notifier.error(err.response.data.detail);
        } else {
          const { data } = err.response;
          if (data && data.name) {
            const [error] = data.name;
            this.errors.cagName = error;
          }
        }
        this.requestInProgress.contactAccessGroupAction = false;
      });
    }
  }

  @action.bound async removeContactAccessGroup(id) {
    this.errors.contactAccessGroupAction = null;
    this.requestInProgress.contactAccessGroupAction = true;
    try {
      await deleteContactAccessGroup(id);
      runInAction(() => {
        this.requestInProgress.contactAccessGroupAction = false;
      });
    } catch (e) {
      runInAction(() => {
        this.errors.contactAccessGroupAction = e.response.data;
        this.requestInProgress.contactAccessGroupAction = false;
      });
    }
  }

  @action.bound resetCagData() {
    this.queryBuilder.setQueries({ condition: 'AND', rules: [] });
    this.cagName = '';
    this.cagData = {};
    this.cagManagerVisiblity = false;
    this.errors.cagName = null;
    this.errors.contactAccessGroupAction = null;
  }

  @computed get hasErrors() {
    return this.errors.contactAccessGroupAction || this.errors.cagName;
  }
}

function formatFields(optionFields, metadataFields) {
  const fields = {};
  Object.keys(metadataFields).forEach((key) => {
    fields[key] = {
      ...metadataFields[key],
      ...optionFields[key],
      label: key
        .split('.')[0]
        .toLowerCase()
        .split('_')
        .map((string, index) => {
          if (index === 0) {
            return string.charAt(0).toUpperCase() + string.substring(1);
          }
          return string;
        })
        .join(' '),
    };
  });
  return fields;
}
