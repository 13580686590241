import { cloneDeep } from 'lodash';

function formatValues(rule) {
  if (rule.value && Array.isArray(rule.value)) {
    return { ...rule, value: rule.value.map(entry => entry.id || entry.value || entry) };
  }
  if (rule.rules) {
    return {
      ...rule,
      rules: rule.rules.map(formatValues),
    };
  }
  return rule;
}

export default function formatQueryRulesArrayValues(queries) {
  const queriesCopy = cloneDeep(queries);
  if (queriesCopy.rules && Array.isArray(queriesCopy.rules)) {
    return {
      ...queriesCopy,
      rules: queriesCopy.rules.map(formatValues),
    };
  }
  return queriesCopy;
}
