import { action, observable, computed, runInAction } from 'mobx';
import { notifier } from 'tc-biq-design-system';
import find from 'lodash/find';
import { partition, omit } from 'lodash';

import formatPayload from 'App/services/utilities/formatPayload';
import stores from 'App/rootStore';

const initialState = {
  users: [],
};

const ERROR_MESSAGES = {
  REQUIRED: 'This field is required',
  INVITE_FAILED: 'Inviting users failed',
};

const text = {
  PASSWORD_CHANGE_SUCCESS: 'Email sent with password change link!',
  PASSWORD_CHANGE_FAILURE: 'Failed to request password change!',
  ADD_SIGNATURE_SUCECESS: 'Successfully edited signature',
  ADD_SIGNATURE_FAILURE: 'Failed to edit signature',
  REMOVE_USER_SUCCESS: 'Successfully removed user',
};

export class UsersStore {
  constructor(UsersService, manageUsersStore) {
    this.usersService = UsersService;
    this.manageUsers = manageUsersStore;
  }

  @observable users = [];

  @observable user = {};

  @observable requestInProgress = {
    user: false,
    updateUser: false,
    uploadAvatar: false,
    addSignature: false,
    initiatePasswordChange: false,
  };

  @observable errors = {
    uploadAvatar: null,
    deleteUser: null,
  };

  @observable inviteRequestStatus = 'idle';

  @observable isInviteUsersTableReady = false;

  @observable userFields = {};

  @action.bound async addUser({ id }) {
    const { role, contact_access_group, ...data } = stores.forms.addUser.data;
    if (contact_access_group == null) {
      stores.forms.addUser.setFieldsErrors({
        contact_access_group: ERROR_MESSAGES.REQUIRED,
      });
      return false;
    }
    if (role == null) {
      stores.forms.addUser.setFieldsErrors({ role: ERROR_MESSAGES.REQUIRED });
      return false;
    }
    if (data.team == null) {
      stores.forms.addUser.setFieldsErrors({ team: ERROR_MESSAGES.REQUIRED });
      return false;
    }
    if (!(await this.checkEmailExists(data.email))) {
      return false;
    }
    const payload = {
      roles: role || [],
      contact_access_groups: contact_access_group || [],
      ...data,
    };

    runInAction(() => {
      if (id != null) {
        this.users[id] = { id, ...payload };
        this.users = this.users.slice();
      } else {
        this.users = [...this.users.slice(), { id: this.users.length, ...payload }];
      }
    });
    stores.forms.addUser.resetFieldsData();
    return true;
  }

  @action.bound async checkEmailExists(email) {
    try {
      await this.usersService.checkEmailExists({ email });
      return true;
    } catch (err) {
      stores.forms.addUser.setFieldsErrors(err.response.data);
      return false;
    }
  }

  @action.bound removeUser(id) {
    this.users = this.users.filter(user => user.id !== id);
  }

  @action.bound editUserDetails(id, isSelfEdit) {
    const formattedPayload = formatPayload(stores.forms.editUser.data);
    const {
      team_binding__team,
      role_bindings__role,
      contact_access_group_bindings__contact_access_group,
      ...payload
    } = formattedPayload;
    if (!isSelfEdit) {
      if (team_binding__team) {
        payload.team_binding = { team: team_binding__team };
      }
      if (role_bindings__role) {
        payload.role_bindings = role_bindings__role.map(value => ({
          role: value,
        }));
      }
      if (contact_access_group_bindings__contact_access_group) {
        // eslint-disable-next-line
        payload.contact_access_group_bindings = contact_access_group_bindings__contact_access_group.map(
          value => ({ contact_access_group: value })
        );
      }
    }
    return this.usersService.editUserDetails(id, payload);
  }

  @action.bound async updateUserDetails({ id, messages }) {
    this.requestInProgress.updateUser = true;
    try {
      const payload = formatPayload(stores.forms.userDetailsForm.data);
      await this.usersService.updateUser(id, {
        ...omit(payload, ['contact_access_group_bindings', 'role_bindings', 'team_binding']),
      });
      notifier.success(messages.SUCCESS);
      runInAction(() => {
        this.requestInProgress.updateUser = false;
      });
    } catch (err) {
      notifier.error(messages.ERROR);
      if (err.response && err.response.data) {
        stores.forms.userDetailsForm.setFieldsErrors(err.response.data);
      }
      runInAction(() => {
        this.requestInProgress.updateUser = false;
      });
    }
  }

  @action.bound async uploadAvatar({ id, payload, notifyMsgs }) {
    this.requestInProgress.uploadAvatar = true;
    const { setUserData, user } = stores.loginStore;
    try {
      const response = await this.usersService.uploadAvatar(id, payload);
      notifier.success(notifyMsgs.success);
      if (id === user.id) setUserData(response.data);
      runInAction(() => {
        this.user = response.data;
      });
    } catch (err) {
      notifier.error(notifyMsgs.error);
      runInAction(() => {
        this.errors.uploadAvatar = err.response;
      });
    } finally {
      runInAction(() => {
        this.requestInProgress.uploadAvatar = false;
      });
    }
  }

  @action.bound async deleteUser(id) {
    runInAction(() => {
      this.errors.deleteUser = false;
    });
    try {
      await this.usersService.deleteUser(id);
      notifier.success(text.REMOVE_USER_SUCCESS);
    } catch (err) {
      runInAction(() => {
        this.errors.deleteUser = true;
      });
    }
  }

  @action.bound resetUsersState() {
    this.users = initialState.users;
    this.isInviteUsersTableReady = false;
    this.inviteRequestStatus = 'idle';
  }

  @action.bound setInviteGridToReady() {
    this.isInviteUsersTableReady = true;
  }

  @action.bound async fetchUserFields() {
    const response = await this.usersService.userOptions();
    const fieldsOptions = response.data.actions.GET;
    runInAction(() => {
      this.userFields = fieldsOptions;
    });
  }

  @action.bound async fetchUser(id) {
    this.requestInProgress.user = true;
    const { setUserData, user } = stores.loginStore;
    try {
      const response = await this.usersService.fetchUser(id);
      runInAction(() => {
        this.user = response.data;
        if (id === user.id) setUserData(response.data);
        const { role_bindings, contact_access_group_bindings } = response.data;
        stores.forms.userDetailsForm.setFieldsData({
          ...response.data,
          role_bindings: role_bindings.map(({ role_name, role }) => ({
            value: role,
            display_name: role_name,
          })),
          contact_access_group_bindings: contact_access_group_bindings.map(
            ({ contact_access_group, contact_access_group_name }) => ({
              value: contact_access_group,
              display_name: contact_access_group_name,
            })
          ),
        });
        this.requestInProgress.user = false;
      });
    } catch (e) {
      runInAction(() => {
        this.requestInProgress.user = false;
      });
    }
  }

  @action.bound async setEditUserDetailsData({ id }) {
    const response = await this.usersService.fetchUser(id);
    // TODO: Set the values for mutliple-select fields.
    const { contact_access_group_bindings, role_bindings, team_binding, ...data } = response.data;
    stores.forms.editUser.setFieldsData({
      team_binding__team:
        team_binding != null
          ? { value: team_binding.team, display_name: team_binding.team_name }
          : null,
      role_bindings__role:
        role_bindings != null
          ? role_bindings.map(role => ({
            value: role.role,
            display_name: role.role_name,
          }))
          : null,
      contact_access_group_bindings__contact_access_group:
        contact_access_group_bindings != null
          ? contact_access_group_bindings.map(cag => ({
            value: cag.contact_access_group,
            display_name: cag.contact_access_group_name,
          }))
          : null,
      ...data,
    });
  }

  @action.bound setAddUserData(userId) {
    const { id, contact_access_groups, roles, ...data } = find(this.users, {
      id: userId,
    });
    stores.forms.addUser.setFieldsData({
      ...data,
      role: roles,
      contact_access_group: contact_access_groups,
    });
  }

  @action.bound resetUserFields() {
    this.userFields = {};
  }

  @action.bound inviteUsers() {
    const payload = this.users.slice().map((user) => {
      const contact_access_groups = user.contact_access_groups.map(
        contact_access_group => contact_access_group.value
      );
      const roles = user.roles.map(role => role.value);
      return {
        email: user.email,
        first_name: user.first_name,
        last_name: user.last_name,
        team: user.team.value,
        contact_access_groups,
        roles,
      };
    });
    this.usersService
      .inviteUsers({
        users: payload,
        url: window.location.origin,
      })
      .then(() => {
        runInAction(() => {
          this.inviteRequestStatus = 'success';
        });
      })
      .catch((err) => {
        if (err.response.data.detail) {
          notifier.error(err.response.data.detail);
        } else if (err.response.data.users) {
          notifier.error(err.response.data.users[0]);
        } else {
          notifier.error(ERROR_MESSAGES.INVITE_FAILED);
        }
      });
  }

  @action.bound resendInvite(id) {
    return this.usersService.resendInvite([id]);
  }

  @action.bound setErrors(key, value) {
    this.errors[key] = value;
  }

  @action.bound resetErrors(key) {
    this.errors[key] = null;
  }

  @computed get hasUsers() {
    return this.users.length > 0;
  }

  @action.bound async initiatePasswordChange() {
    try {
      this.requestInProgress.initiatePasswordChange = true;
      await this.usersService.changePassword();
      notifier.success(text.PASSWORD_CHANGE_SUCCESS);
    } catch (e) {
      notifier.success(text.PASSWORD_CHANGE_FAILURE);
    } finally {
      runInAction(() => {
        this.requestInProgress.initiatePasswordChange = false;
      });
    }
  }

  // email integrations

  @observable emailInboxes = [];

  @observable sharedEmailInboxes = [];

  @observable loading = {
    fetchEmailInboxes: false,
    actionEmailInboxesId: null,
    connectEmailInboxes: false,
  };

  @action.bound async fetchEmailInboxes() {
    this.loading.fetchEmailInboxes = true;
    try {
      const response = await this.usersService.fetchEmailInbox();
      runInAction(() => {
        [this.emailInboxes, this.sharedEmailInboxes] = partition(
          response.data.results,
          inbox => !inbox.shared_by
        );
      });
    } catch {
      notifier.error('Failed to fetch email inboxes');
    } finally {
      runInAction(() => {
        this.loading.fetchEmailInboxes = false;
      });
    }
  }

  @action.bound async connectEmailInbox(url) {
    this.loading.connectEmailInboxes = true;
    try {
      const response = await this.usersService.connectEmailInbox(url);
      window.location.href = response.data.auth_url;
    } catch (err) {
      if (err.response && err.response.data.detail) {
        notifier.error(err.response.data.detail);
      } else {
        notifier.error('Failed to connect inbox');
      }
    } finally {
      runInAction(() => {
        this.loading.connectEmailInboxes = false;
      });
    }
  }

  @action.bound async deleteEmailInbox(id) {
    try {
      await this.usersService.deleteEmailInbox(id);
      runInAction(() => {
        this.emailInboxes = this.emailInboxes.filter(inbox => inbox.id !== id);
      });
      notifier.success('Successfully deleted inbox');
    } catch {
      notifier.error('Failed to delete inbox');
    }
  }

  @action.bound async revokeEmailInbox(id, onError) {
    try {
      await this.usersService.revokeEmailInbox(id);
      await this.fetchEmailInboxes();
      notifier.success('Successfully revoked inbox');
    } catch {
      onError();
      notifier.error('Failed to revoke inbox');
    }
  }

  @action.bound async reconnectEmailInbox(email, redirectUrl) {
    try {
      const response = await this.usersService.reconnectEmailInbox(email, redirectUrl);
      window.location.href = response.data.auth_url;
    } catch {
      notifier.error('Failed to reconnect inbox');
    }
  }

  @action.bound async addEmailInboxSignature(id, signature) {
    this.requestInProgress.addSignature = true;
    try {
      await this.usersService.addEmailInboxSignature(id, signature);
      notifier.success(text.ADD_SIGNATURE_SUCECESS);
    } catch (err) {
      notifier.error(text.ADD_SIGNATURE_FAILURE);
    } finally {
      runInAction(() => {
        this.requestInProgress.addSignature = false;
        this.fetchEmailInboxes();
      });
    }
  }
}

export default UsersStore;
