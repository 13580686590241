import http from 'App/services/http';

const fetchIntegrations = () => http.get('/config/integrations/');

const setIntegrationConfig = (key, value) => http.post('/config/integrations/', { key, value });

const generateIntegrationSecret = key => http.post('/config/integrations/auto-generate-secert/', { key });

const initiateOAuthLogin = url => http.get(url, {
  params: { redirect_url: window.location.href },
  withCredentials: true,
});

export { fetchIntegrations, setIntegrationConfig, generateIntegrationSecret, initiateOAuthLogin };
