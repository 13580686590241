import React from 'react';
import { number, string } from 'prop-types';
import AvatarNameCell from './AvatarNameCell';

const propTypes = {
  id: number.isRequired,
  fullName: string.isRequired,
  avatar: string,
};

const defaultProps = {
  avatar: null,
};

const ContactCell = ({ id, fullName, avatar }) => (
  <AvatarNameCell
    id={id}
    type="Contact"
    displayString={fullName}
    avatarSource={avatar}
    route="/contacts/"
  />
);

ContactCell.propTypes = propTypes;
ContactCell.defaultProps = defaultProps;
export default ContactCell;
