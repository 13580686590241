import React, { Suspense, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { observer, inject } from 'mobx-react';
import { bool, func, string } from 'prop-types';
import LoadingPage from 'App/components/LoadingPage';
import { BrowserRouter, Route, Redirect, Switch } from 'react-router-dom';

import ProtectedRoute from 'App/components/ProtectedRoute';

const LoginPage = React.lazy(() => import(/* webpackPrefetch: true */ 'Auth/pages/LoginPage'));
const Main = React.lazy(() => import('App/layout/Main'));

const propTypes = {
  homeRoute: string.isRequired,
  setUserData: func.isRequired,
  permissionsLoaded: bool.isRequired,
  fetchUserPermissions: func.isRequired,
  isLoadingPermissions: bool.isRequired,
  setGetTokenFunc: func.isRequired,
};

const AppRouter = ({
  setUserData,
  homeRoute,
  permissionsLoaded,
  fetchUserPermissions,
  isLoadingPermissions,
  setGetTokenFunc,
}) => {
  const { isAuthenticated, isLoading, getAccessTokenSilently, user, logout } = useAuth0();
  const params = new URLSearchParams(window.location.search);
  const isPasswordChanged = params.get('success');

  useEffect(() => {
    const logoutWithReturnTo = () => {
      logout({ returnTo: window.location.origin });
    };
    // NOTE: If user changed his password, we need to logout him from existing
    // session and prompt him with login form.
    if (isPasswordChanged && isPasswordChanged === 'true' && isAuthenticated) {
      logoutWithReturnTo();
      return;
    }
    if (isAuthenticated) {
      // NOTE: We set the Auth0 function for getting the token in the store,
      // since it can only be retrieved through `useAuth0` hook.
      setGetTokenFunc(async () => getAccessTokenSilently({ scope: 'all' }));
      setUserData(user);
      fetchUserPermissions(logoutWithReturnTo);
    }
  }, [isAuthenticated]);

  if (isLoading || (isLoadingPermissions && !permissionsLoaded)) {
    return <LoadingPage fullScreen />;
  }

  return (
    <BrowserRouter>
      <Switch>
        {isAuthenticated && permissionsLoaded && <Redirect strict from="/login" to={homeRoute} />}
        <Suspense fallback={<LoadingPage fullScreen />}>
          <ProtectedRoute isAllowed={isAuthenticated} path="/" component={Main} />
          <Route exact path="/login" component={LoginPage} />
        </Suspense>
      </Switch>
    </BrowserRouter>
  );
};

AppRouter.propTypes = propTypes;

export default inject(stores => ({
  permissionsLoaded: stores.permissions.permissionsLoaded,
  homeRoute: stores.navigator.homeRoute,
  setUserData: stores.loginStore.setUserData,
  setGetTokenFunc: stores.loginStore.setGetTokenFunc,
  fetchUserPermissions: stores.permissions.fetchUserPermissions,
  isLoadingPermissions: stores.permissions.requestInProgress.fetchPermissions,
}))(observer(AppRouter));
