import React from 'react';
import { any } from 'prop-types';


const DefaultCell = ({ value }) => (
  <div className="truncate-text">
    {value || '-'}
  </div>
);

DefaultCell.propTypes = { value: any };
DefaultCell.defaultProps = { value: null };
export default DefaultCell;
