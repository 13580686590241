export default {
  INTEGER: 'integer',
  FLOAT: 'float',
  DECIMAL: 'decimal',
  BOOLEAN: 'boolean',
  CHOICE: 'choice',
  EMAIL: 'email',
  DATE: 'date',
  DATETIME: 'datetime',
  GENERIC_RELATION: 'generic relation',
  RELATED_FIELD: 'related field',
  NESTED_OBJECT: 'nested object',
  LIST_FIELD: 'list',
};
