import { action, observable, runInAction } from 'mobx';
import { differenceBy, uniq } from 'lodash';

import { parseParamString } from 'App/components/Filters/filterStoreUtils';
import { searchUsers } from 'Settings/Sections/Users/services/UsersService';
import errorFormatter from 'App/services/utilities/errorFormatter';
import ManageUsersServiceFactory from './ManageUsersServiceFactory';

const initialData = {
  lists: {
    users: [],
    bindUsers: [],
  },
  scrollProps: {
    users: {
      hasMore: false,
      query: { cursor: '', ordering: '-id' },
    },
    bindUsers: {
      hasMore: false,
      query: { offset: 0, limit: 20 },
    },
  },
};

export default class ManageUsersStore {
  constructor(endpoint) {
    this.service = new ManageUsersServiceFactory(endpoint);
  }

  @observable requestInProgress = {
    users: false,
    bindUsers: false,
    updateBindings: false,
  };

  @observable errors = {
    users: null,
    bindUsers: null,
    updateBindings: null,
  };

  @observable lists = {
    users: [],
    bindUsers: [],
    bindCount: 0,
  };

  @observable scrollProps = {
    users: {
      hasMore: false,
      query: { cursor: '', ordering: '-id' },
    },
    bindUsers: {
      hasMore: false,
      query: { offset: 0, limit: 20 },
    },
  };

  initialBindings = [];

  // manage users modal actions
  @action.bound async addUserBindings(selectedUser) {
    this.lists.bindUsers = [selectedUser, ...this.lists.bindUsers];
    this.lists.users = filterSelectedUsers(this.lists.users, this.lists.bindUsers);
  }

  @action.bound async removeUserBindings(selectedUser) {
    this.lists.users = [selectedUser, ...this.lists.users];
    this.lists.bindUsers = filterSelectedUsers(this.lists.bindUsers, this.lists.users);
  }

  @action.bound removeAllBindings() {
    this.lists.bindUsers = [];
  }

  @action.bound async updateUserBindings({ addedUsers, removedUsers, id, isRemoveAll }) {
    const promises = [];
    const added = differenceBy(addedUsers, removedUsers, 'id');
    const removed = differenceBy(removedUsers, addedUsers, 'id');
    if (added.length && !isRemoveAll) {
      promises.push(this.service.addBindings(id, formatUsersPayload(added)));
    }
    if (removed.length && !isRemoveAll) {
      promises.push(this.service.removeBindings(id, formatUsersPayload(removed)));
    }
    if (isRemoveAll) {
      promises.push(this.service.removeAllBindings(id));
    }
    if (promises.length) {
      this.requestInProgress.updateBindings = true;
      this.errors.updateBindings = null;
      try {
        await Promise.all(promises);
        runInAction(() => {
          this.requestInProgress.updateBindings = false;
        });
      } catch (e) {
        runInAction(() => {
          const errorData = Array.isArray(e.response.data[0])
            ? e.response.data[0]
            : errorFormatter(e.response.data);
          this.errors = { ...this.errors, updateBindings: errorData };
          this.requestInProgress.updateBindings = false;
        });
      }
    }
  }

  @action.bound async fetchListData(key, searchTerm, isOnScroll, id) {
    if (this.requestInProgress[key] && !this.scrollProps[key].hasMore) return;
    if (!isOnScroll) this.scrollProps[key].query.cursor = '';
    this.requestInProgress[key] = true;
    try {
      const params = {
        search: searchTerm,
        ...this.scrollProps[key].query,
      };
      let response = {};
      if (id) {
        response = await this.service.fetchUserBindings(id, params);
      } else {
        response = await searchUsers(params);
      }
      const { results, next, count } = response.data;
      const { cursor, offset } = parseParamString(next);
      runInAction(() => {
        this.scrollProps[key].hasMore = !!next;
        this.scrollProps[key].query = getQuery(this.scrollProps, key, cursor, offset);
        if (key === 'bindUsers') {
          this.lists.bindCount = count || 0;
          this.initialBindings = isOnScroll
            ? uniq([...this.initialBindings, ...results.map(user => user.id)])
            : results.map(user => user.id);
        }
        const formattedData = key === 'users'
          ? filterSelectedUsers(results, this.lists.bindUsers)
          : formatBindUsers(results);
        this.lists[key] = isOnScroll ? [...this.lists[key], ...formattedData] : formattedData;
        this.requestInProgress[key] = false;
      });
    } catch (errors) {
      runInAction(() => {
        this.errors[key] = errors.data;
        this.requestInProgress[key] = false;
      });
    }
  }

  @action.bound resetLists() {
    this.lists = initialData.lists;
    this.scrollProps = initialData.scrollProps;
    this.searchListTerm = initialData.searchListTerm;
    this.errors = {
      users: null,
      bindUsers: null,
      updateBindings: null,
    };
  }
}

function filterSelectedUsers(users, bindUsers) {
  return differenceBy(users, bindUsers, 'id');
}

function formatBindUsers(data) {
  return data.map(record => ({
    bindingId: record.id,
    ...record.user,
  }));
}

function getQuery(scrollProps, key, cursor, offset) {
  if (key === 'users') return { ...scrollProps[key].query, cursor: cursor || '' };
  if (key === 'bindUsers') return { ...scrollProps[key].query, offset: offset || 0 };
  return scrollProps[key];
}

function formatUsersPayload(users) {
  return users.map(user => ({ user: user.id }));
}
