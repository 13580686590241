import { isMatch, isEmpty } from 'lodash';
import moment from 'moment';

import extractNestedFields from 'App/services/utilities/extractNestedFields';
import fieldTypes from 'App/enums/fieldTypes';
import getRelatedModels from 'App/services/utilities/getRelatedModels';

export {
  isNonValue,
  parseParamString,
  notSame,
  formatToQueries,
  formatValuePerType,
  formatQuickFilter,
  extractNestedFields,
  formatSavedFilters,
  setDefaultFilter,
  extractActiveFilters,
  isDayAgo,
  isExact,
  isRange,
  isIn,
};

const formatTimeDate = date => date.format('YYYY-MM-DD HH:mm:ss').replace(/\+/, ' ');
const parseDateUtc = dateString => moment(dateString, 'YYYY-MM-DD').utc(true);

function formatValuePerType(fieldType, operatorValue, value) {
  if ((!isDayAgo(operatorValue) && fieldType === fieldTypes.DATE) || operatorValue === 'date') {
    return moment(value, 'YYYY-MM-DD').format('YYYY-MM-DD');
  }

  if (!isDayAgo(operatorValue) && fieldType === fieldTypes.DATETIME && !isRange(operatorValue)) {
    switch (operatorValue) {
      case 'lt':
        return formatTimeDate(parseDateUtc(value).subtract(1, 'Days'));
      case 'gt':
        return formatTimeDate(parseDateUtc(value).add(1, 'Days'));
      default:
        return formatTimeDate(parseDateUtc(value).startOf('day'));
    }
  }
  if (Array.isArray(value)) {
    return value.map(element => formatValuePerType(fieldType, operatorValue, element)).join(',');
  }
  if (typeof value === 'object') return value.value;
  if (nonValueOperators.includes(operatorValue)) return true;

  return value;
}

function formatModelQuery(modelType, fieldType) {
  if (!modelType || fieldType !== fieldTypes.GENERIC_RELATION) return '';
  return `__${modelType}__id`;
}

function formatToQueries(activeFilters) {
  const filterQueries = {};
  activeFilters.forEach((filter) => {
    const relatedModelQuery = formatModelQuery(filter.value.relatedModel, filter.field.type);
    filterQueries[
      `${filter.field.value}${relatedModelQuery}__${filter.operator.value}`
    ] = formatValuePerType(filter.field.type, filter.operator.value, filter.value);
  });
  return filterQueries;
}

function formatQuickFilter(filter) {
  const operatorValue = filter.key === 'id' ? 'exact' : 'contains';
  const operatorLabel = filter.key === 'id' ? 'is equal to' : 'contains';
  return {
    field: { type: 'string',
      value: filter.key,
      label: filter.key[0].toUpperCase() + filter.key.slice(1).split('_').join(' '),
    },
    operator: { label: operatorLabel, related_models: null, value: operatorValue },
    value: filter.value,
  };
}

function notSame(active, current) {
  return !isMatch(formatToQueries([active]), formatToQueries([current]));
}

function parseParamString(string) {
  const query = {};
  if (!string) return query;
  const formatedString = string.indexOf('?') > -1 ? string.split('?')[1] : string;
  formatedString.split('&').forEach((entry) => {
    const [key, value] = entry.split('=');
    if (key === 'cursor') {
      query[key] = decodeURIComponent(value);
    } else if (key === 'limit') {
      query[key] = Number(value);
    } else {
      query[key] = value;
    }
  });
  return query;
}

function setDefaultFilter(activeFilter, savedFilters) {
  if (!isEmpty(activeFilter)) return activeFilter;
  const [defaultFilter] = savedFilters.filter(filter => filter.is_default);
  return defaultFilter;
}

function formatSavedFilters(filters, userId) {
  const initialValue = {
    created: [],
    favorites: [],
    shared: [],
  };
  const temp = filters.reduce((acc, filterRecord) => {
    const { filter, is_favorite, is_default, id } = filterRecord;
    if (filterRecord.is_favorite) {
      return {
        ...acc,
        favorites: [
          ...acc.favorites,
          { ...filter, isFavorite: is_favorite, isDefault: is_default, filterId: id },
        ],
      };
    }
    if (filter.owner && filter.owner === userId) {
      return {
        ...acc,
        created: [
          ...acc.created,
          { ...filter, isFavorite: is_favorite, isDefault: is_default, filterId: id },
        ],
      };
    }
    return {
      ...acc,
      shared: [
        ...acc.shared,
        { ...filter, isFavorite: is_favorite, isDefault: is_default, filterId: id },
      ],
    };
  }, initialValue);
  return temp;
}

function extractActiveFilters(urlFilterQueries, optionFields) {
  if (!optionFields) return [];
  return Object.keys(urlFilterQueries)
    .map((key) => {
      const decodedQuery = decodeURIComponent(key);
      const { field, fieldId } = getField(optionFields, decodedQuery);
      if (!field) return null;
      const operator = getOperator(field.lookups, decodedQuery);
      const value = getValue(urlFilterQueries[key], operator);
      return {
        field: {
          label: field.label,
          value: fieldId,
          type: field.type,
        },
        operator: {
          label: field.lookups[operator],
          value: operator,
          related_models: getRelatedModels(field.related_models, field.related_model),
        },
        value,
      };
    })
    .filter(field => field);
}

const rangeOpreators = ['range', 'range!'];
function isRange(operator) {
  return rangeOpreators.includes(operator);
}

const nonValueOperators = ['isnull', 'isnull!'];
function isNonValue(operator) {
  return nonValueOperators.includes(operator);
}

const inOperators = ['in', 'in!'];
function isIn(operator) {
  return inOperators.includes(operator);
}

const dayAgoOperators = ['days_ago_exact', 'days_ago_gt', 'days_ago_lt'];
function isDayAgo(operator) {
  return dayAgoOperators.includes(operator);
}

const exactOperators = ['exact', 'exact!'];
function isExact(operator) {
  return exactOperators.includes(operator);
}

function exportRangeValue(value) {
  return value.split(',');
}

function getField(optionFields, queryString) {
  const queryArray = queryString.split('__');
  queryArray.splice(-1, 1);
  const [fieldId, nestedId] = queryArray;
  const id = nestedId ? `${fieldId}__${nestedId}` : fieldId;
  const field = optionFields[fieldId] || optionFields[id];
  if (field.type === 'nested object') {
    return {
      field: extractNestedFields(optionFields, true, true)[fieldId],
      fieldId,
    };
  }
  return { field, fieldId };
}

function getOperator(lookups, queryString) {
  return Object.keys(lookups).filter(operatorKey => queryString.includes(operatorKey))[0];
}

function getValue(value, operator) {
  if (isRange(operator)) exportRangeValue(value);
  return value;
}
