import { observable, action, computed, runInAction } from 'mobx';

import stores from 'App/rootStore';
import { fetchUserPermissions } from 'App/services/permissionsService';

export default class PermissionsStore {
  @observable userPermissions = [];

  @observable requestInProgress = {
    fetchPermissions: false,
  };

  @action.bound setUserPermissions(permissions) {
    this.userPermissions = permissions;
  }

  @action.bound async fetchUserPermissions(onError) {
    this.requestInProgress.fetchPermissions = true;
    try {
      const response = await fetchUserPermissions();
      runInAction(() => {
        this.setUserPermissions(response.data);
      });
      stores.navigator.filterVisibleItems();
    } catch (e) {
      onError();
    } finally {
      runInAction(() => {
        this.requestInProgress.fetchPermissions = false;
      });
    }
  }

  @computed get permissionsLoaded() {
    return this.userPermissions.length > 0;
  }
}
