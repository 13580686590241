import { observable, action, runInAction } from 'mobx';
import { notifier } from 'tc-biq-design-system';

import {
  fetchCampaignRecipients,
} from 'Marketing/services/CampaignService';

const text = {
  FETCH_CAMPAIGN_FAILED: 'Failed to fetch campaign',
};

export default class CampaignsStore {
  constructor(queryBuilderStore) {
    this.queryBuilder = queryBuilderStore;
  }

  @observable recipients = {};

  @observable fieldsDef = {};

  @observable requestInProgress = {
    fetchRecipients: false,
  };

  @observable errors = {};

  @action.bound async fetchCampaignData(id) {
    this.requestInProgress.fetchRecipients = true;

    try {
      const response = await fetchCampaignRecipients(id);
      runInAction(() => {
        this.recipients = response.data;
      });
    } catch {
      notifier.error(text.FETCH_CAMPAIGN_FAILED);
    } finally {
      runInAction(() => {
        this.requestInProgress.fetchRecipients = false;
      });
    }
  }
}
