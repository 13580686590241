import http from 'App/services/http';

const fetchWebhook = id => http.get(`/automations/webhooks/${id}`);

const fetchWebhookLog = id => http.get(`/automations/webhooks/logs/${id}`);

const testWebhookConnection = id => http.get(`/automations/webhooks/${id}/test-connection/`);

const fetchWebhookOptions = () => http.options('/automations/webhooks/');

const editWebhook = (id, payload) => http.patch(`/automations/webhooks/${id}/`, payload);

const createWebhook = payload => http.post('/automations/webhooks/', payload);

const deleteWebhook = id => http.delete(`/automations/webhooks/${id}/`);

const generateWebhookSecret = id => http.patch(`/automations/webhooks/${id}/generate-secret`);

export {
  fetchWebhook,
  fetchWebhookLog,
  testWebhookConnection,
  deleteWebhook,
  fetchWebhookOptions,
  createWebhook,
  editWebhook,
  generateWebhookSecret,
};
