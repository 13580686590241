import { action, runInAction, observable } from 'mobx';

import { fetchContactKyc } from 'Contacts/services/contactsService';

export default class KycStore {
  @observable kycData = [];

  @observable requestInProgress = false;

  @observable errors = null;

  @action.bound async fetchKycData(id) {
    this.requestInProgress = true;
    try {
      const response = await fetchContactKyc(id);
      runInAction(() => {
        this.kycData = response.data.results;
        this.requestInProgress = false;
      });
    } catch (err) {
      this.requestInProgress = false;
      this.errors = err.data;
    }
  }
}
