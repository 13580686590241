import { action, runInAction, observable } from 'mobx';

import { fetchContactMifidData } from 'Contacts/services/contactsService';

export default class MifidStore {
  @observable mifidData = [];

  @observable requestInProgress = false;

  @observable errors = null;

  @action.bound async fetchMifidData(id) {
    this.requestInProgress = true;
    try {
      const response = await fetchContactMifidData(id);
      runInAction(() => {
        this.mifidData = response.data.results;
        this.requestInProgress = false;
      });
    } catch (err) {
      this.requestInProgress = false;
      this.errors = err.data;
    }
  }
}
