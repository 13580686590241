import { observable, action, computed } from 'mobx';
import { find } from 'lodash';

import {
  notSame,
  formatToQueries,
  extractNestedFields,
  formatSavedFilters,
  formatQuickFilter,
  setDefaultFilter,
  extractActiveFilters,
} from 'App/components/Filters/filterStoreUtils';
import stores from 'App/rootStore';

const initialValues = {
  savedFilters: {
    created: [],
    favorites: [],
    shared: [],
  },
};

export default class FiltersStore {
  @observable fields = {};

  @observable activeFilters = [];

  @observable activeFilter = {};

  @observable defaultActiveFilter = {};

  @observable savedFilters = initialValues.savedFilters;

  @observable rawSavedFilters = null;

  @observable filterQuery = {};

  @observable activeSegment = null;

  @observable segmentFilterQuery = {};

  @observable viewName = null;

  // Filter actions

  @action.bound addNewFilter(newFilter) {
    const formattedFilter = formatToQueries([newFilter]);
    Object.keys(formattedFilter).forEach((key) => {
      if (this.filterQuery[key] || this.filterQuery[key] === false) {
        this.activeFilters = this.activeFilters
          .map(filter => (filter.field.value === newFilter.field.value
            ? { ...filter, value: newFilter.value } : filter))
          .filter(item => item.value);
      } else {
        this.activeFilters = this.activeFilters
          .slice()
          .concat(newFilter)
          .filter(item => item.value);
      }
    });
    this.filterQuery = { ...formatToQueries(this.activeFilters) };
  }

  @action.bound addNewQuickFilter(newQuickFilter) {
    const formatedQuickFilter = formatQuickFilter(newQuickFilter);
    this.addNewFilter(formatedQuickFilter);
  }

  @action.bound removeFilter(filter) {
    this.activeFilters = this.activeFilters
      .slice()
      .filter(activeFilter => notSame(activeFilter, filter));
    this.filterQuery = { ...formatToQueries(this.activeFilters) };
  }

  @action.bound resetFilters() {
    this.activeFilters = [];
    this.activeFilter = {};
    this.segmentFilterQuery = {};
    this.activeSegment = null;
    this.filterQuery = {};
    this.savedFilters = initialValues.savedFilters;
    this.rawSavedFilters = null;
    this.defaultActiveFilter = {};
    this.viewName = null;
  }

  @action.bound updateFilterQuery(filterQuery) {
    this.filterQuery = { ...filterQuery };
  }

  @action.bound updateActiveFilters(activeFilters) {
    this.activeFilters = [...activeFilters];
  }

  @action.bound updateFields(fields) {
    this.fields = extractNestedFields({ ...fields }, true, true);
  }

  @action.bound updateViewName(name) {
    this.viewName = name;
  }

  @action.bound updateSavedFilters(savedFilters) {
    this.savedFilters = formatSavedFilters(savedFilters, stores.loginStore.user.id);
    this.rawSavedFilters = savedFilters;
    this.defaultActiveFilter = setDefaultFilter(this.activeFilter, savedFilters);
  }

  @action.bound setActiveFilter(filterId) {
    const activeFilter = find(this.rawSavedFilters, { id: filterId });
    this.activeFilter = activeFilter;
    this.filterQuery = { ...activeFilter.filter.filters };
    this.activeFilters = extractActiveFilters(activeFilter.filter.filters, this.fields);
  }

  @action.bound emptyActiveFilter() {
    this.activeFilter = {};
  }

  // Segment Filter actions

  @action.bound addSegmentFilter(segmentFilter) {
    this.segmentFilterQuery = {
      segment_ids: segmentFilter.value.id,
    };
    this.activeSegment = segmentFilter.value;
  }

  @action.bound removeSegmentFilter() {
    this.segmentFilterQuery = {};
    this.activeSegment = null;
  }

  @computed get query() {
    return { ...this.filterQuery, ...this.segmentFilterQuery };
  }
}
